import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Dropdown, Menu, Radio } from 'antd';
// import './adaptive-learning-layout-styles.css';
// import '../../../../pages/execute-solutions/experiment-list-style.css';
import { CustomRecordType } from '../experiment-list-layout';
import capitalize from 'lodash/capitalize';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  SwapOutlined,
} from '@ant-design/icons';

type SortByDropdownProps = {
  onChange: (value: string) => void;
  tableColumns: CustomRecordType[];
  setTableColumns: (data: CustomRecordType[]) => void;
  sortOrder: string;
  setSortOrder: Dispatch<SetStateAction<string>>;
};
export const SortByDropdown = ({
  onChange,
  tableColumns,
  setTableColumns,
  sortOrder,
  setSortOrder,
}: SortByDropdownProps) => {
  const [selectedValue, setSelectedValue] = useState<string>();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const handleMenuClick = (e: any) => {
    if (e.key === 'toggleOrder') {
      const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      setSortOrder(newOrder);
    } else {
      setSelectedValue(e.key);
      onChange(e.key);
    }
  };

  const handleClearSorting = () => {
    onChange('experiment');
    setSortOrder('asc');
    setSelectedValue(undefined);
  };

  const premadeFilters = ['cost', 'actions', 'satisfaction', 'experiment'];

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{ maxHeight: 200, overflow: 'auto', padding: 0 }}
    >
      <Menu.Item
        key="toggleOrder"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          background: 'white',
          padding: '8px 8px 2px 8px',
        }}
      >
        {sortOrder === 'asc' ? (
          <>
            <ArrowUpOutlined style={{ paddingRight: '8px' }} />
            Ascending
          </>
        ) : (
          <>
            <ArrowDownOutlined style={{ paddingRight: '8px' }} />
            Descending
          </>
        )}
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item
        key="experiment"
        style={{
          backgroundColor:
            selectedValue === 'experiment' ? '#ffe6e6' : undefined,
        }}
      >
        Date Added
      </Menu.Item>
      <Menu.Item
        key="cost"
        style={{
          backgroundColor: selectedValue === 'cost' ? '#ffe6e6' : undefined,
        }}
      >
        Unit Cost (Lowest)
      </Menu.Item>
      <Menu.Item
        key="satisfaction"
        style={{
          backgroundColor:
            selectedValue === 'satisfaction' ? '#ffe6e6' : undefined,
        }}
      >
        Customer Satisfaction
      </Menu.Item>
      {tableColumns
        ?.filter(tc => tc.visible && !premadeFilters.includes(tc.key))
        .map(tc => (
          <Menu.Item
            key={tc.key}
            style={{
              backgroundColor: selectedValue === tc.key ? '#ffe6e6' : undefined,
            }}
          >
            {tc.columnType === 'composition'
              ? capitalize(tc.sourceRecord.name)
              : capitalize(tc.key)}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
        <Dropdown
          overlay={menu}
          open={dropdownOpen}
          onOpenChange={setDropdownOpen}
        >
          <Button>
            <SwapOutlined />
            {selectedValue
              ? `${capitalize(selectedValue)} (${sortOrder})`
              : 'Sort By'}
          </Button>
        </Dropdown>
        {selectedValue && (
          <Button danger onClick={() => handleClearSorting()}>
            Clear sorting
          </Button>
        )}
      </div>
    </>
  );
};
