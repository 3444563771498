/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Button, Divider, Tabs } from 'antd';
import { DesignContextProvider } from '../../_shared/context/design-context';
import './experiment-list-style.css';
import { useSession } from '../../_shared/context';
import { useNavigate, useLocation } from 'react-router-dom';
import ExperimentListLayout from '../../components/experiments/experiment-list-layout';
import { ScenarioDetailContextProvider } from '../../_shared/context/scenario-detail-context';
import { css } from '@emotion/react';
import {
  EventHistory,
  WorkspaceType,
  usecreateIterationMutation,
} from '../../../../__generated__/globalTypes';
import { ProjectHistory } from '../../components/project-history/project-history';
import { useExecuteSolutions } from '../../_shared/context/execute-solutions-context';
import { TrackableEvent, logEvent } from '../../_shared/tracking/usage-tracker';
import { ProjectGoalManagementDashboard } from '../../components/project-goal-management-dashboard/project-goal-management-dashboard';
import { useTranslation } from 'react-i18next';
import ExperimentListLayoutV2 from '../../components/experiments/experiment-list-layout-v2';
import { useFeatureContext } from '../../_shared/context/features-context';
import { ProductFeatureType } from '@prisma/client';

enum ExecuteSolutionsTabs {
  EXPERIMENTS = 'EXPERIMENTS',
  HISTORY = 'HISTORY',
  GOAL_MANAGEMENT_DASHBOARD = 'GOAL_MANAGEMENT_DASHBOARD',
}

export type EventHistoryRecordType = Omit<
  EventHistory,
  'projectId' | 'createdBy'
> & {
  createdBy: {
    id: string;
    firstName?: string;
    lastName?: string;
  };
};

const ExecuteSolutionsLayout: React.FC = () => {
  const { t } = useTranslation();
  const locations = useLocation();
  const { currentProject, useFetchProject } = useSession();
  const { selectedIteration } = useExecuteSolutions();
  const { isEnableFeature } = useFeatureContext();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<ExecuteSolutionsTabs>();
  const [
    defaultExplorationIteration,
    setDefaultExplorationIteration,
  ] = useState<any>();
  const [
    createIteration,
    { loading: createIterationLoading, error: createIterationError },
  ] = usecreateIterationMutation();

  const renderTab = () => {
    if (locations.pathname.endsWith('history')) {
      return <ProjectHistory />;
    }
    if (locations.pathname.endsWith('goal_management_dashboard')) {
      return <ProjectGoalManagementDashboard />;
    }
    return null;
  };

  useEffect(() => {
    const endOfPath = locations.pathname.split('/').pop();

    if (
      Object.keys(ExecuteSolutionsTabs).includes(endOfPath?.toUpperCase() ?? '')
    ) {
      setCurrentTab(endOfPath?.toUpperCase() as ExecuteSolutionsTabs);
    }
  }, [locations.pathname]);

  const fetchIteration = async () => {
    const projectMissingExistingExploration =
      currentProject?.iterations.filter(
        iteration => iteration.type === WorkspaceType.EXPLORATION
      ).length === 0;

    if (currentProject?.lastSetupStep === 'COMPLETE') {
      if (currentProject && projectMissingExistingExploration) {
        let { data } = await createIteration({
          variables: {
            data: {
              name: 'Untitled Exploration',
              projectId: currentProject.id,
              type: WorkspaceType.EXPLORATION,
            },
          },
        });
        setDefaultExplorationIteration(data?.createOneIteration.id);
      } else {
        currentProject?.iterations?.[currentProject?.iterations.length - 1].id;
      }
    }

    setDefaultExplorationIteration(
      currentProject?.iterations?.filter(
        i => i.type === WorkspaceType.EXPLORATION
      )[0]?.id
    );
  };

  useEffect(() => {
    fetchIteration();
  }, []);

  const updateCurrentTab = (newTab: ExecuteSolutionsTabs) => {
    navigate(`/project/${currentProject?.id}/${newTab.toLowerCase()}`);
  };

  let newES = isEnableFeature[ProductFeatureType.EXECUTE_SOLUTIONS_V2];

  return <>
    {!newES &&
      <ScenarioDetailContextProvider iteration={selectedIteration}>
        <DesignContextProvider iteration={selectedIteration}>
          <div
            className="container"
            css={css`
            .ant-tabs-nav-list {
              margin-left: 60px;
            }
          `}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '40px 60px 20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ fontSize: '24px', fontWeight: 600 }}>
                  {currentProject?.name}
                </div>
                <div style={{ display: 'flex', gap: 10 }}>
                  <Button
                    onClick={() => {
                      logEvent(TrackableEvent.DISCOVER_SOLUTION_CLICKED);
                      navigate(
                        `/project/${currentProject?.id}/explore/${defaultExplorationIteration}`
                      );
                    }}
                  >
                    Go To Discover Solutions
                  </Button>
                </div>
              </div>
            </div>
            <Tabs
              onChange={activeKey =>
                updateCurrentTab(activeKey as ExecuteSolutionsTabs)
              }
              activeKey={currentTab}
              items={[
                {
                  label: t('execute-solution.page.projectExperimentsTab.title'),
                  key: ExecuteSolutionsTabs.EXPERIMENTS,
                  children: <ExperimentListLayout />,
                },
                {
                  label: t('execute-solution.page.projectHistoryTab.title'),
                  key: ExecuteSolutionsTabs.HISTORY,
                  children: <ProjectHistory />,
                },
                {
                  label: t('execute-solution.page.projectGoalManagerTab.title'),
                  key: ExecuteSolutionsTabs.GOAL_MANAGEMENT_DASHBOARD,
                  children: <ProjectGoalManagementDashboard />,
                },
              ]}
            />
            <Divider style={{ margin: 0 }} />
          </div>
        </DesignContextProvider>
      </ScenarioDetailContextProvider>
    }
    {newES &&
      <ScenarioDetailContextProvider iteration={selectedIteration}>
        <DesignContextProvider iteration={selectedIteration}>
          {!locations.pathname.endsWith('experiments') &&
            <div
              className="container"
              css={css`
            .ant-tabs-nav-list {
              margin-left: 60px;
            }
          `}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px 60px 20px 60px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ fontSize: '24px', fontWeight: 600 }}>
                    {currentProject?.name}
                  </div>
                  <div style={{ display: 'flex', gap: 10 }}>
                  </div>
                </div>
              </div>
              {renderTab()}
              <Divider style={{ margin: 0 }} />
            </div>
          }
          {locations.pathname.endsWith('experiments') &&
            <ExperimentListLayoutV2 />
          }
        </DesignContextProvider>
      </ScenarioDetailContextProvider>
    }
  </>
};

export default ExecuteSolutionsLayout;
