/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import './formulation-generated-style.less';
import { ArrowDown, Info, Like } from '../../../_shared/style';
import { FormulationType } from '../../../_shared/context/formulations-context';
import { useExploration, useSession } from '../../../_shared/context';
import { useDesign } from '../../../_shared/context/design-context';
import {
  FormulationFeedbackSubjectType,
  FormulationFeedbackType,
  FormulationItemType,
  ProjectFeature,
  usecreateOneFormulationFeedbackMutation,
} from '../../../../../__generated__/globalTypes';
import { Flex, Popover, Tooltip, notification } from 'antd';
import { CommentOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useIngredients } from '../../../_shared/hooks';
import _ from 'lodash';
import posthog from 'posthog-js';
import { CommentForm } from '../../experiments/comments/comment-form';

export const ExplorationTimelineFormulationGenerated = ({
  handleChangeFormulationToView,
  data,
  design,
  isBenchmark,
}: {
  handleChangeFormulationToView: (formulationToView: FormulationType) => void;
  data: Record<string, any>;
  design: string | undefined | null;
  isBenchmark?: boolean;
}) => {
  const { currentProject } = useSession();
  const { previousDesigns } = useDesign();
  const { ingredientByName } = useIngredients();
  const { setFormulationStatus, setFormulationFeedback } = useExploration();
  const [createFeedbackNote] = usecreateOneFormulationFeedbackMutation();
  const [costEnable, setCostEnable] = useState(true);
  const [ingredientsExpanded, setIngredientsExpanded] = useState(true);
  const [outcomesExpanded, setOutcomesExpanded] = useState(true);
  const [hasPositiveOutcomeFeedback, setHasPositveOutcomeFeedback] = useState(
    false
  );
  const [hasNegativeOutcomeFeedback, setHasNegativeOutcomeFeedback] = useState(
    false
  );
  const [
    hasPositiveIngredientFeedback,
    setHasPositiveIngredientFeedback,
  ] = useState(false);
  const [
    hasNegativeIngredientFeedback,
    setHasNegativeIngredientFeedback,
  ] = useState(false);

  const [formulationExpanded, setIsExpanded] = useState(false);

  const ingredientsExpansion = () => {
    setIsExpanded(!formulationExpanded);
  };

  const setFeedbackStatus = (
    feedback: FormulationType['formulationFeedback'][0]
  ) => {
    const isPositive = feedback.type === FormulationFeedbackType.POSITIVE;
    const isNegative = feedback.type === FormulationFeedbackType.NEGATIVE;
    if (
      isPositive &&
      feedback.subjectType === FormulationFeedbackSubjectType.OUTCOME
    ) {
      if (feedback.isActive) {
        setHasPositveOutcomeFeedback(true);
        setHasNegativeOutcomeFeedback(false);
      } else {
        setHasPositveOutcomeFeedback(false);
        setHasNegativeOutcomeFeedback(false);
      }
    }

    if (
      isPositive &&
      feedback.subjectType === FormulationFeedbackSubjectType.INGREDIENT
    ) {
      if (feedback.isActive) {
        setHasPositiveIngredientFeedback(true);
        setHasNegativeIngredientFeedback(false);
      } else {
        setHasPositiveIngredientFeedback(false);
        setHasNegativeIngredientFeedback(false);
      }
    }

    if (
      isNegative &&
      feedback.subjectType === FormulationFeedbackSubjectType.OUTCOME
    ) {
      if (feedback.isActive) {
        setHasNegativeOutcomeFeedback(true);
        setHasPositveOutcomeFeedback(false);
      } else {
        setHasNegativeOutcomeFeedback(false);
        setHasPositveOutcomeFeedback(false);
      }
    }

    if (
      isNegative &&
      feedback.subjectType === FormulationFeedbackSubjectType.INGREDIENT
    ) {
      if (feedback.isActive) {
        setHasNegativeIngredientFeedback(true);
        setHasPositiveIngredientFeedback(false);
      } else {
        setHasNegativeIngredientFeedback(false);
        setHasPositiveIngredientFeedback(false);
      }
    }
  };

  useEffect(() => {
    if (currentProject) {
      let find = false;
      currentProject.features.map(f => {
        if (f.feature === ProjectFeature.PRICING) {
          find = true;
        }
      });
      setCostEnable(find);
    }
  }, [currentProject]);

  useEffect(() => {
    const formulationRecord = data.formulationRecord as FormulationType;

    if (formulationRecord.formulationFeedback) {
      const oFeedback = formulationRecord.formulationFeedback.filter(
        e => e.subjectType === FormulationFeedbackSubjectType.OUTCOME
      );
      const iFeedback = formulationRecord.formulationFeedback.filter(
        e => e.subjectType === FormulationFeedbackSubjectType.INGREDIENT
      );
      if (oFeedback.length > 0) {
        setFeedbackStatus(oFeedback[oFeedback.length - 1]);
      }
      if (iFeedback.length > 0) {
        setFeedbackStatus(iFeedback[iFeedback.length - 1]);
      }
    }

    // const feedback = formulationRecord.formulationFeedback[formulationRecord.formulationFeedback.length - 1]
    // if (feedback) {
    //   setFeedbackStatus(feedback)
    // }
  }, [data.formulationRecord]);

  const topObjectivesSet = new Set();
  const associatedDesign = previousDesigns.filter(d => d?.id === design)?.[0];
  if (!associatedDesign) {
    currentProject?.activeModel?.outcomes.forEach(o => {
      topObjectivesSet.add(o.targetVariable);
    });
  }

  associatedDesign?.objectives
    .sort((a, b) => b.importance - a.importance)
    .slice(0, 9)
    .forEach(o => {
      topObjectivesSet.add(o.targetVariable);
    });

  const handleCreateFeedback = async (
    type: FormulationFeedbackType,
    subjectType: FormulationFeedbackSubjectType,
    isActive?: boolean
  ) => {
    try {
      // posthog.capture('FEEDBACK');
      const res = await setFormulationFeedback(
        data.formulationRecord,
        type,
        subjectType,
        isActive
      );
      if (res.data?.createOneFormulationFeedback) {
        setFeedbackStatus(res.data.createOneFormulationFeedback);
      }
    } catch (error) {
      notification.error({ message: 'Error submitting feedback' });
    }
  };

  const [{ isDragging }, drag] = useDrag({
    type: 'formulationKey',
    item: { formulationKey: data },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const formulationRecord = data.formulationRecord as FormulationType;

  return (
    <div
      className="formulation-generated"
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
    >
      <div
        className="header"
        css={css`
          border-bottom: 1px solid #e0e0e0;
          flex-direction: column;
          display: flex;
          padding-bottom: 6px;
        `}
      >
        <div className="title">
          <div
            css={css`
              color: #161f26;
              cursor: pointer;
              font-family: Inter;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              text-decoration: underline;
            `}
            onClick={() => handleChangeFormulationToView(formulationRecord)}
          >
            {data.formulation}
          </div>
          {!isBenchmark && (
            <div className="action-buttons">
              {/* <Tooltip
                className='explanation-tooltip'
                title={data.explanation}
              >
                <div className="info">
                  <InfoCircleOutlined className="info-circle">

                  </InfoCircleOutlined>
                </div>
              </Tooltip> */}

              <Popover
                content={
                  <CommentForm
                    formulationKey={formulationRecord.key}
                    formulationId={formulationRecord.id}
                  />
                }
                trigger="click"
                placement="left"
                style={{ paddingBottom: 0 }}
              >
                <CommentOutlined />
              </Popover>
              {/*<div
                className={`pin ${data?.pinned ? 'active' : ''}`}
                onClick={() => handleFormulationStatus()}
              >
                <Pin />
              </div>*/}
            </div>
          )}
        </div>

        {/*
        FIX EXPLANATIONS
        <div
          className={`description ${formulationExpanded ? 'expanded' : ''}`}
          css={css`
            color: #8e8e8e;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
          `}
        >
          {data.explanation}
        </div>
        {!isBenchmark && (
          <div className="text-toggle" onClick={ingredientsExpansion}>
            {formulationExpanded ? (
              <span>Show less...</span>
            ) : (
              <span>Show more...</span>
            )}
          </div>
        )} */}
      </div>
      {costEnable && (
        <div
          className="row cost-row"
          css={css`
            justify-content: center !important;
          `}
        >
          <div className="portion">
            {currentProject?.monetaryUnit} {data.cost.toFixed(3)}
          </div>
        </div>
      )}
      <Flex justify="space-between" style={{ padding: '10px 0' }}>
        <Flex
          gap={5}
          align="center"
          style={{ cursor: 'pointer', padding: '8px 0' }}
          onClick={() => setIngredientsExpanded(!ingredientsExpanded)}
        >
          {ingredientsExpanded ? (
            <ArrowDown
              style={{
                transition: 'all ease 0.3s',
              }}
            />
          ) : (
            <ArrowDown
              style={{
                rotate: '-90deg',
                transition: 'all ease 0.3s',
              }}
            />
          )}
        </Flex>
        {!isBenchmark && (
          <Flex gap={10} align="center">
            <Tooltip
              style={{ marginLeft: 5 }}
              title="If these values meet your expectations, give them a thumbs up. If they are not trending in the right direction, give them a thumbs down."
            >
              <div className="info">
                <Info />
              </div>
            </Tooltip>
            <div
              className={`like ${
                hasPositiveIngredientFeedback ? 'complete' : ''
              }`}
              onClick={() =>
                handleCreateFeedback(
                  FormulationFeedbackType.POSITIVE,
                  FormulationFeedbackSubjectType.INGREDIENT,
                  !hasPositiveIngredientFeedback
                )
              }
            >
              <Like />
            </div>
            <div
              className={`dislike ${
                hasNegativeIngredientFeedback ? 'complete' : ''
              }`}
              onClick={() =>
                handleCreateFeedback(
                  FormulationFeedbackType.NEGATIVE,
                  FormulationFeedbackSubjectType.INGREDIENT,
                  !hasNegativeIngredientFeedback
                )
              }
            >
              <Like />
            </div>
          </Flex>
        )}
      </Flex>
      {ingredientsExpanded &&
        formulationRecord.items
          .filter(
            item =>
              item.type !== FormulationItemType.TARGET_MEASURED &&
              item.type !== FormulationItemType.TARGET_PREDICTED
          )
          .map(item => {
            const ingredient = ingredientByName.get(item.variable.name);
            return (
              <div
                className="row"
                css={css`
                  justify-content: center !important;
                `}
              >
                <div className="portion">
                  {!isNaN(Number(item.value)) && !ingredient?.unit
                    ? item.value + '%'
                    : item.value + ingredient?.unit}
                </div>
              </div>
            );
          })}
      <Flex justify="space-between" style={{ padding: '10px 0' }}>
        <Flex
          gap={5}
          align="center"
          style={{ cursor: 'pointer', padding: '8px 0' }}
          onClick={() => setOutcomesExpanded(!outcomesExpanded)}
        >
          {outcomesExpanded ? (
            <ArrowDown
              style={{
                transition: 'all ease 0.3s',
              }}
            />
          ) : (
            <ArrowDown
              style={{
                rotate: '-90deg',
                transition: 'all ease 0.3s',
              }}
            />
          )}
        </Flex>
        {!isBenchmark && (
          <Flex gap={10} align="center">
            <Tooltip
              style={{ marginLeft: 5 }}
              title="If you want more values like this, give a thumbs up. If you want less outcome values like these, give them a thumbs down."
            >
              <div className="info">
                <Info />
              </div>
            </Tooltip>
            <div
              className={`like ${hasPositiveOutcomeFeedback ? 'complete' : ''}`}
              onClick={() =>
                handleCreateFeedback(
                  FormulationFeedbackType.POSITIVE,
                  FormulationFeedbackSubjectType.OUTCOME,
                  !hasPositiveOutcomeFeedback
                )
              }
            >
              <Like />
            </div>
            <div
              className={`dislike ${
                hasNegativeOutcomeFeedback ? 'complete' : ''
              }`}
              onClick={() =>
                handleCreateFeedback(
                  FormulationFeedbackType.NEGATIVE,
                  FormulationFeedbackSubjectType.OUTCOME,
                  !hasNegativeOutcomeFeedback
                )
              }
            >
              <Like />
            </div>
          </Flex>
        )}
      </Flex>
      {outcomesExpanded &&
        currentProject?.activeModel?.outcomes
          .filter(o => topObjectivesSet.has(o.targetVariable))
          .map(outcome => {
            return (
              <div
                className="row"
                css={css`
                  justify-content: center !important;
                `}
              >
                <div
                  className="portion"
                  css={css`
                    display: flex;
                    justify-content: center;
                    gap: 6px;
                    width: 100%;
                  `}
                >
                  {data?.[outcome?.targetVariable]}
                </div>
              </div>
            );
          })}
    </div>
  );
};
