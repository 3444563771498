/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Popover,
  Flex,
  Input,
  Button,
  notification,
  Menu,
  Dropdown,
} from 'antd';
import {
  DownloadOutlined,
  DownOutlined,
  FolderOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Dispatch, useEffect, useState } from 'react';
import { usecreateOneTestPlanMutation } from '../../../../../__generated__/globalTypes';
import {
  TestPlanWithFormulationsType,
  useExecuteSolutions,
} from '../../../_shared/context';
import { ResultsSummaryComponent } from './results-summary';
import { TestPlanDropdowns } from './test-plan-dropdowns';

export const TestPlanComponentV2 = ({
  handleOnFormulationDrop,
  handleOnReviewTestPlan,
  setFilterCurrentCampaign,
  filterCurrentCampaign,
  campaignFilteredId,
  setCampaignFilteredId,
  roundSelectedId,
  setRoundSelectedId,
  customTestPlanFilteredId,
  setCustomTestPlanFilteredId,
}: {
  handleOnFormulationDrop(
    formulationKey: string,
    testPlanId: string,
    action: 'ADD' | 'REMOVE'
  ): void;
  handleOnReviewTestPlan(selectedTestPlan: TestPlanWithFormulationsType): void;
  setFilterCurrentCampaign: React.Dispatch<React.SetStateAction<boolean>>;
  filterCurrentCampaign: boolean;
  campaignFilteredId: String | undefined;
  setCampaignFilteredId: Dispatch<React.SetStateAction<String | undefined>>;
  roundSelectedId: String | undefined;
  setRoundSelectedId: Dispatch<React.SetStateAction<String | undefined>>;
  customTestPlanFilteredId: String | undefined;
  setCustomTestPlanFilteredId: Dispatch<
    React.SetStateAction<String | undefined>
  >;
}) => {
  const {
    testPlansWithFormulations,
    setTestPlansWithFormulations,
  } = useExecuteSolutions();
  const [showSidebar, setShowSidebar] = useState(true);

  const [testPlanSummaryToShow, setTestPlanSummaryToShow] = useState<
    TestPlanWithFormulationsType | undefined
  >();

  const [collapsedTestPlans, setCollapsedTestPlans] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    if (
      Object.entries(collapsedTestPlans).length === 0 &&
      testPlansWithFormulations.length > 0
    ) {
      const updatedCollapsedTestPlans = testPlansWithFormulations.reduce(
        (acc, item, index) => {
          if (!item) return acc;

          acc[item.id] = index === 0;
          return acc;
        },
        {} as Record<string, boolean>
      );

      setCollapsedTestPlans(updatedCollapsedTestPlans);
    }
  }, [testPlansWithFormulations, collapsedTestPlans]);

  const toggleTestPlan = (testPlanId: string) => {
    setCollapsedTestPlans(prevState => ({
      ...prevState,
      [testPlanId]: !prevState[testPlanId],
    }));
  };

  const CloseSidebarButton = () => (
    <div>
      <Button
        icon={
          <DownloadOutlined
            style={{
              rotate: showSidebar ? '90deg' : '-90deg',
              color: showSidebar ? '#D3D6D9' : 'inherit',
            }}
          />
        }
        onClick={() => setShowSidebar(!showSidebar)}
        type="text"
      />
    </div>
  );

  const handleTestPlanProcessingComplete = (
    updatedTestPlan: TestPlanWithFormulationsType
  ) => {
    const existingPlanIndex = testPlansWithFormulations.findIndex(
      testPlan => testPlan?.id === updatedTestPlan?.id
    );

    if (existingPlanIndex > -1) {
      const updatedPlans = [...testPlansWithFormulations];
      updatedPlans[existingPlanIndex] = updatedTestPlan;

      setTestPlansWithFormulations(updatedPlans);
      setTestPlanSummaryToShow(updatedTestPlan);
    }
  };

  if (!showSidebar) {
    return (
      <div
        css={css`
          width: 60px;
          display: flex;
          justify-content: center;
        `}
      >
        <CloseSidebarButton />
      </div>
    );
  }

  return (
    <>
      <div
        css={css`
          width: 290px;
          display: flex;
          flex-direction: column;
          position: relative;
          border-radius: 20px;
          max-height: 100%;
          overflow-y: scroll;
          scrollbar-width: thin;
        `}
      >
        <TestPlanDropdowns
          testPlansWithFormulations={testPlansWithFormulations}
          toggleTestPlan={toggleTestPlan}
          collapsedTestPlans={collapsedTestPlans}
          handleOnFormulationDrop={handleOnFormulationDrop}
          handleTestPlanProcessingComplete={handleTestPlanProcessingComplete}
          setTestPlanSummaryToShow={setTestPlanSummaryToShow}
          setFilterCurrentCampaign={setFilterCurrentCampaign}
          filterCurrentCampaign={filterCurrentCampaign}
          campaignFilteredId={campaignFilteredId}
          setCampaignFilteredId={setCampaignFilteredId}
          roundSelectedId={roundSelectedId}
          setRoundSelectedId={setRoundSelectedId}
          customTestPlanFilteredId={customTestPlanFilteredId}
          setCustomTestPlanFilteredId={setCustomTestPlanFilteredId}
        />
      </div>
      {testPlanSummaryToShow && (
        <ResultsSummaryComponent
          testPlan={testPlanSummaryToShow}
          handleOnReviewTestPlan={testPlanToReview => {
            handleOnReviewTestPlan(testPlanToReview);
            setTestPlanSummaryToShow(undefined);
          }}
          handleCloseSummary={() => setTestPlanSummaryToShow(undefined)}
        />
      )}
    </>
  );
};
