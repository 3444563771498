import { Button, Flex, Input } from 'antd';
import React, { useState } from 'react';

export const AddTestPlanInput = ({
  onAddTestPlan,
}: {
  onAddTestPlan: (name: string) => void;
}) => {
  const [newTestPlanName, setNewTestPlanName] = useState('');

  return (
    <Flex vertical gap={12}>
      <Input
        placeholder="Test plan name..."
        value={newTestPlanName}
        onChange={event => setNewTestPlanName(event.target.value)}
        required
        minLength={1}
        maxLength={50}
      />
      <Button
        onClick={() => {
          onAddTestPlan(newTestPlanName);
          setNewTestPlanName('');
        }}
      >
        Add Test Plan
      </Button>
    </Flex>
  );
};
