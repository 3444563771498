import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const TuringUniversityRedirect: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.replace('/turing-university/', '');

    if (path)
      window.location.href = `https://university.turingsaas.com/${path}`;
  }, [location.pathname]);

  return null;
};
