import { useMutation, useQuery } from 'react-query';
import { CampaignRepository } from '../repositories/campaign.repository';

export const useCreateCampaign = () => {
  const mutation = useMutation((data: any) => {
    return CampaignRepository.postCreateCampaign(data);
  });
  return mutation;
};
export const useRetryFailedCampaign = () => {
  const mutation = useMutation((data: any) => {
    return CampaignRepository.postRetryFailedCampaign(data);
  });
  return mutation;
};

export const useGetCampaign = (data: {
  campaignId?: string;
}, enabled: boolean): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getCampaign`, data.campaignId],
    CampaignRepository.getCampaign,
    {
      enabled: !!data.campaignId && enabled,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const usePutCampaign = () => {
  const mutation = useMutation((data: any) => {
    return CampaignRepository.putCampaign(data);
  });
  return mutation;
};

export const useSoftDeleteCampaign = () => {
  const mutation = useMutation((data: { campaignId: string }) => {
    return CampaignRepository.softDeleteCampaign(data)
  });
  return mutation;
}

