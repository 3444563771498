import React, { useState } from 'react';
import { Button, Table } from 'antd';
import {
  FormulationType,
  useFormulations,
} from '../../../_shared/context/formulations-context';
import { useSession } from '../../../_shared/context';
import {
  Formulation,
  FormulationItemMetricType,
  FormulationItemType,
  FormulationStatus,
} from '../../../../../__generated__/globalTypes';
import './exploration-results-table-styles.less';
import {
  confidenceIntervalsStringToArray,
  convertReliabilityPercentage,
  hasValue,
} from '../../../_shared/utils/util';
import { ConfidenceIntervalText } from '../confidence-interval-text';
import { useDesign } from '../../../_shared/context/design-context';
import { ExplorationTimelineFormulationGenerated } from '../formulation-details/formulation-generated';
import { ArrowDown } from '../../../_shared/style';
import { useScenarioDetail } from '../../../_shared/context/scenario-detail-context';
import { ExplorationTimelineFormulationItemsList } from '../formulation-details/formulation-items-list';

type RecordType = {
  key: string;
  dataKey: string;
  formulationRecord: Formulation;
  formulation: string;
  cost: string;
  explanation: string;
  pinned: FormulationStatus;
  action: string;
};
export const ExplorationResultTable = ({
  handleChangeFormulationToView,
  formulations,
}: {
  handleChangeFormulationToView: (formulationToView: FormulationType) => void;
  formulations: FormulationType[];
}) => {
  const { projectBenchmarkFormulation } = useFormulations();
  const { previousDesigns, runQuickDesign, quickDesignIsRunning } = useDesign();
  const { objectivesByTarget } = useScenarioDetail();
  const { currentProject } = useSession();
  const formulationsToView = [
    ...formulations.filter(f => f.isBenchmark === false),
    ...(projectBenchmarkFormulation ? [projectBenchmarkFormulation] : []),
  ];

  const designId = formulations?.[0]?.designId;
  const associatedDesign = previousDesigns.filter(d => d?.id === designId)?.[0];
  const topObjectivesSet = new Set();

  if (!associatedDesign) {
    currentProject?.activeModel?.outcomes.forEach(o => {
      topObjectivesSet.add(o.targetVariable);
    });
  }

  const handleGenerateExperiment = async () => {
    if (!quickDesignIsRunning) {
      await runQuickDesign({
        passedObjectivesByTarget: objectivesByTarget,
        reasonMessage: 'Running Quick AL',
      });
    }
  };

  associatedDesign?.objectives
    .sort((a, b) => b.importance - a.importance)
    // .slice(0, 3)
    .forEach(o => {
      topObjectivesSet.add(o.targetVariable);
    });

  const hasExplanations =
    formulationsToView.findIndex(formulation =>
      hasValue(formulation.description)
    ) > -1;

  const dataSource = formulationsToView
    .map(formulation => {
      const outcomeValues = formulation?.items.filter(item => {
        if (formulation?.isBenchmark) {
          return item.type === FormulationItemType.TARGET_MEASURED;
        } else {
          return item.type === FormulationItemType.TARGET_PREDICTED;
        }
      });
      const hasConfidenceIntervals = outcomeValues.some(value =>
        value.metrics.some(
          metric =>
            metric.type === FormulationItemMetricType.CONFIDENCE_INTERVAL
        )
      );

      const data: Record<string, any> = {
        key: formulation.key,
        dataKey: formulation.key,
        formulationRecord: formulation,
        formulation: formulation.isBenchmark
          ? 'Benchmark'
          : formulation.name ?? formulation.key,
        cost: formulation.totalCostScore,
        explanation: formulation.description,
        pinned: formulation.status === FormulationStatus.PINNED,
        action: '',
        isBenchmark: formulation.isBenchmark,
      };

      for (const value of outcomeValues) {
        const reliabilityMetric = value.metrics.find(
          metric => metric.type === FormulationItemMetricType.RELIABILITY
        );

        const confidenceInterval = value.metrics.find(
          metric =>
            metric.type === FormulationItemMetricType.CONFIDENCE_INTERVAL
        );
        const reliabilityPercentage = convertReliabilityPercentage(
          reliabilityMetric?.value
        );
        if (hasConfidenceIntervals) {
          data[value.variable.name] = (
            <>
              <div>{value?.value}</div>
              <div>
                <ConfidenceIntervalText
                  confidenceInterval={confidenceIntervalsStringToArray(
                    confidenceInterval?.value
                  )}
                  reliabilityPercentage={reliabilityPercentage}
                />
              </div>
            </>
          );
        } else {
          data[value.variable.name] = value?.value;
        }
        data.reliabilityPercentage = reliabilityPercentage;
      }
      return data;
    })
    .reverse();

  return dataSource.length > 0 ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      {dataSource && (
        <div className="formulation-container">
          {dataSource.map((data, index) => {
            return (
              <>
                {index % 4 === 0 && (
                  <>
                    <ExplorationTimelineFormulationItemsList
                      data={data}
                      design={designId}
                    />
                  </>
                )}
                <ExplorationTimelineFormulationGenerated
                  key={data.key}
                  data={data}
                  design={designId}
                  handleChangeFormulationToView={handleChangeFormulationToView}
                  isBenchmark={data.isBenchmark}
                />
              </>
            );
          })}
        </div>
      )}
      {/* <p className={`give-more`} onClick={() => handleGenerateExperiment()}>
        Give me More
        <ArrowDown />
      </p> */}
    </div>
  ) : null;
};
