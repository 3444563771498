import { useIngredients } from '../../../../_shared/hooks';
import {
  ConstraintType,
  CostOptimizationOption,
  FormulationItemType,
  JobStatus,
  ObjectiveType,
} from '../../../../../../__generated__/globalTypes';
import { FormulationType } from '../../../../_shared/context/formulations-context';
import { CustomRecordType } from '../../experiment-list-layout-v2';
import { Card, Table, Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

export const ConstraintsCard = ({
  design,
}: {
  design:
    | {
        __typename?: 'Design';
        id: string;
        maxNumberOfResults?: number | null;
        costOptimizationOption?: CostOptimizationOption | null;
        nteCost?: number | null;
        createdAt: any;
        enforceStrictly: boolean;
        iterationId: string;
        modelId: string;
        updatedAt: any;
        projectJob?: {
          __typename?: 'ProjectJobs';
          id: string;
          status: JobStatus;
        } | null;
        objectives: Array<{
          __typename?: 'Objective';
          id: string;
          iterationId?: string | null;
          targetVariable: string;
          objectiveType: ObjectiveType;
          importance: number;
          value?: string | null;
          lower?: string | null;
          upper?: string | null;
          createdAt: any;
          updatedAt: any;
          minTarget?: number | null;
          maxTarget?: number | null;
        }>;
        constraints: Array<{
          __typename?: 'Constraint';
          id: string;
          constraintType: ConstraintType;
          lowerBounds?: number | null;
          upperBounds?: number | null;
          coefficients?: Array<any> | null;
          values?: Array<any> | null;
          variables?: Array<string> | null;
          name?: string | null;
          ingredientCompositionId?: string | null;
        }>;
      }
    | null
    | undefined;
}) => {
  const constraints = design?.constraints;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Constraint Type',
      dataIndex: 'constraintType',
      key: 'constraintType',
    },
    {
      title: 'Range / Value',
      key: 'rangeOrValue',
      render: (record: {
        constraintType: string;
        lowerBounds: any;
        upperBounds: any;
        values: string | any[];
      }) => {
        if (
          record.constraintType === 'RANGE' ||
          record.constraintType === 'COUNT'
        ) {
          return `${record.lowerBounds} - ${record.upperBounds}`;
        }
        if (record.constraintType === 'EQUALITY' && record.values.length > 0) {
          return `${record.values[0].value}`;
        }
        return '-';
      },
    },
  ];

  return (
    <>
      {constraints && (
        <Card title="Constraints">
          <Table
            columns={columns}
            dataSource={constraints}
            pagination={false}
          />
        </Card>
      )}
      <Card title={'Cost optimization option'}>
        {design?.costOptimizationOption === 'DO_NOT_OPTIMIZE'
          ? 'Not optimized'
          : design?.costOptimizationOption === 'LIMIT'
          ? `Cost limit: ${design.nteCost}`
          : design?.costOptimizationOption}
      </Card>
    </>
  );
};
