/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import {
  Button,
  RadioChangeEvent,
  Radio,
  Empty,
  Flex,
  Modal,
  Typography,
  notification,
  Switch,
} from 'antd';
import { useDesign } from '../../_shared/context/design-context';
import '../../pages/execute-solutions/experiment-list-style.css';
import { useSession } from '../../_shared/context';
import { useIngredients } from '../../_shared/hooks';
import {
  FormulationSource,
  JobStatus,
  Outcome,
  usetriggerTestPlanModelRebuildMutation,
  VariableType,
} from '../../../../__generated__/globalTypes';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { ExperimentListTableComponent } from './experiment-list-table-component';
import { SetViewDropdown } from './set-view-dropdown/set-view-dropdown';
import { MetricsDropdown } from './metric-dropdown/metric-dropdown';
import { UploadResultsDrawer } from './upload-results-drawer/upload-results-drawer';
import { getFormulationsExport } from '../workspaces/adaptive-learning/design-utils';
import { TrackableEvent, logEvent } from '../../_shared/tracking/usage-tracker';
import { IsolateButton } from './experiment-list-table/isolate-button';
import { FilterValue } from 'antd/lib/table/interface';
import { ExperimentBarChart } from './experiment-list-chart-component';
import { FilterDropdown } from './filter-dropdown/filters-dropdowns';
import { css } from '@emotion/react';
import { useExecuteSolutions } from '../../_shared/context/execute-solutions-context';
import {
  roundFormulations,
  useFormulations,
} from '../../_shared/context/formulations-context';
import { useDeleteFormulation } from '../../network/services/formulation.service';
import { ExperimentListTableComponentV2 } from './experiment-list-table-component-v2';
import { CopilotChat } from './copilot/chat';

export type CustomRecordType = Record<string, any>;
export enum FilterTypeEnum {
  BETWEEN = 'between',
  GREATER_THAN = 'greaterThan',
  LESS_THAN = 'lessThan',
  EQUAL_TO = 'equalTo',
}

export type FilterType = {
  id: string;
  selectedItem: string | null; // For the first Select component
  filterType: FilterTypeEnum; // For the second Select component
  inputValue?: number | string; // For single input scenarios (Less Than, Greater Than, Equal To)
  startValue?: number;
  endValue?: number;
  error?: string;
};
export const chartKeyBlacklist = [
  'key',
  'isGenerated',
  'isBenchmark',
  'formulation',
  'measured',
  'experiment',
  'result',
  'actions',
];

export enum ViewType {
  TABLE = 'table',
  CHART = 'chart',
}

const ExperimentListLayoutV2: React.FC = () => {
  const { projectFormulations } = useFormulations();
  const [tableKey, setTableKey] = useState(0);
  const [viewType, setViewType] = useState<ViewType>(ViewType.TABLE);
  const [sortBy, setSortBy] = useState<string>('experiment');
  const [chartData, setChartData] = useState<
    { name: string; values: Record<string, any>[] }[]
  >();
  const [tableDataForFilter, setTableDataForFilter] = useState<
    { name: string; values: Record<string, any>[] }[]
  >();
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [view, setView] = useState<string>('recentlyGenerated');
  const [openUploadResultsDrawer, setOpenUploadResultsDrawer] = useState<
    boolean
  >(false);
  const handleUploadResultsDrawerClose = () => {
    setOpenUploadResultsDrawer(false);
  };
  const { latestDesign, previousDesigns, designResults } = useDesign();
  const { currentProject, user } = useSession();
  const {
    selectedIteration,
    selectedDesignId,
    setSelectedDesignId,
    testPlansWithFormulations,
  } = useExecuteSolutions();

  const [filterMenuVisible, setFilterMenuVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [chartFilters, setChartFilters] = useState<FilterType[]>([]);
  const [formulationsToFilter, setFormulationsToFilter] = useState<string[]>(
    []
  );
  const [selectedScenarios, setSelectedScenarios] = useState<string[]>([]);
  const [isolatedRows, setIsolatedRows] = useState<React.Key[]>([]);
  const { ingredients } = useIngredients();
  const [tableData, setTableData] = useState<CustomRecordType[]>([]);
  const [tableColumns, setTableColumns] = useState<CustomRecordType[]>([]);
  const [resetTable, setResetTable] = useState(false);
  const [showCopilot, setShowCopilot] = useState(false);

  const successfulDesigns = previousDesigns.filter(
    pd =>
      pd?.projectJob?.status !== JobStatus.ERROR &&
      pd?.projectJob?.status !== JobStatus.CANCELED
  );
  const [showDeleteFormulationModal, setShowDeleteFormulationModal] = useState(
    false
  );

  const [
    triggerTestPlanModelRebuild,
    { loading: modelRebuildLoading },
  ] = usetriggerTestPlanModelRebuildMutation();

  const deleteManyFormulations = useDeleteFormulation();

  const filename = `${currentProject?.key}-${selectedIteration?.key}-AL-Formulations.csv`;

  const resetAllTable = () => {
    // We need to make sure this is empty before rerendering the table since it's controlled
    setIsolatedRows([]);
    setSelectedScenarios([]);
    setResetTable(true);
  };

  useEffect(() => {
    if (resetTable) {
      setTableKey(tableKey => tableKey + 1);
      setSelectedRowKeys([]);
      setTableFilters({});
      setResetTable(false);
    }
  }, [resetTable]);

  useEffect(() => {
    // generate chartData
    if (tableData?.length > 0) {
      const chartMap = new Map();

      tableData.forEach(item => {
        Object.keys(item).forEach(key => {
          const column = tableColumns.find(tc => tc.key === key);
          if (
            !chartKeyBlacklist.includes(key) &&
            column?.sourceRecord?.type === VariableType.NUMERIC
          ) {
            if (!chartMap.has(key)) {
              chartMap.set(key, []);
            }
            chartMap.get(key)?.push({
              name: item.key,
              [key]: Number(item[key]),
              column,
              originalData: item,
            });
          }
        });
      });
      const chartDataArray: {
        name: string;
        values: Record<string, any>[];
      }[] = [];
      chartMap?.forEach((value, key) => {
        chartDataArray.push({
          name: key,
          values: value,
        });
      });
      setChartData(chartDataArray);
    }
  }, [tableData]);

  useEffect(() => {
    // generate tableData for filter
    if (tableData?.length > 0) {
      const tableMap = new Map();

      tableData.forEach(item => {
        Object.keys(item).forEach(key => {
          const column = tableColumns.find(tc => tc.key === key);
          if (!chartKeyBlacklist.includes(key)) {
            if (!tableMap.has(key)) {
              tableMap.set(key, []);
            }
            tableMap.get(key)?.push({
              name: item.key,
              [key]: item[key],
              column,
              originalData: item,
            });
          }
        });
      });
      const tableDataArray: {
        name: string;
        values: Record<string, any>[];
      }[] = [];
      tableMap?.forEach((value, key) => {
        tableDataArray.push({
          name: key,
          values: value,
        });
      });
      setTableDataForFilter(tableDataArray);
    }
  }, [tableData]);

  useEffect(() => {
    setSelectedDesignId(String(latestDesign?.id));
  }, [latestDesign]);

  const handleIsolateTableRows = () => {
    setTableFilters({
      ...tableFilters,
      experiment: selectedRowKeys.map(key => key),
    });
    setIsolatedRows(selectedRowKeys);
  };

  useEffect(() => {
    if (view === 'default') {
      resetAllTable();
    }
  }, [view]);

  const moveChart = (keyName: string, direction: 'up' | 'down') => {
    if (chartData) {
      const clonedChartData = [...chartData];
      const chartLocation = clonedChartData?.findIndex(
        chart => chart.name === keyName
      );

      const temp = clonedChartData[chartLocation];
      if (
        chartLocation >= 0 &&
        chartLocation < clonedChartData.length - 1 &&
        direction === 'down'
      ) {
        clonedChartData[chartLocation] = clonedChartData[chartLocation + 1];
        clonedChartData[chartLocation + 1] = temp;
      }
      if (chartLocation > 0 && direction === 'up') {
        clonedChartData[chartLocation] = clonedChartData[chartLocation - 1];
        clonedChartData[chartLocation - 1] = temp;
      }
      setChartData(clonedChartData);
      window.scrollBy({
        top: direction === 'up' ? -450 : 450,
        behavior: 'smooth', // You can use 'auto' for instant scrolling
      });
    }
  };

  const handleDeleteManyFormulations = async () => {
    setShowDeleteFormulationModal(false);

    const formulationIds: string[] = [];

    projectFormulations.forEach(formulation => {
      if (formulation.key && selectedRowKeys.includes(formulation.key)) {
        formulationIds.push(formulation.id);
      }
    });

    deleteManyFormulations.mutate(
      {
        organizationId: currentProject!.organizationId,
        projectId: currentProject!.id,
        formulationIds,
      },
      {
        onSuccess: async response => {
          if (response.data.data) {
            if (view === 'testPlan') {
              const latestTestPlanWithUpload = testPlansWithFormulations.find(
                testPlan => Boolean(testPlan?.latestUpload)
              );

              /**
               * If there are no previous test plan uploads, we can assume the model
               * does not need retraining
               */
              try {
                if (latestTestPlanWithUpload?.latestUpload) {
                  await triggerTestPlanModelRebuild({
                    variables: {
                      testPlanUploadId:
                        latestTestPlanWithUpload.latestUpload.id,
                    },
                  });
                }
              } catch (error) {
                notification.error({
                  message: 'Error requesting model rebuild',
                });
              }
            }

            window.location.reload();
          } else {
            notification.error({ message: 'Error deleting formulations' });
          }
        },
        onError: async response => {
          notification.error({ message: 'Error deleting formulations' });
        },
      }
    );
  };

  const handleClearFilters = () => {
    setChartFilters([]);
    setFormulationsToFilter([]);
  };

  const handleTableViewChange = (updatedViewType: ViewType) => {
    setViewType(updatedViewType);
  };

  return (
    <div className="container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 60px 10px',
        }}
      >
        <div
          style={{
            fontSize: '16px',
            fontWeight: 500,
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            top: '24px',
            right: showCopilot ? '400px' : '40px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {tableData?.length > 0 && (
                <Switch
                  checked={viewType === ViewType.TABLE}
                  onChange={(checked: boolean) => {
                    handleTableViewChange(
                      checked ? ViewType.TABLE : ViewType.CHART
                    );
                  }}
                  checkedChildren="Table"
                  unCheckedChildren="Chart"
                  style={{
                    backgroundColor:
                      viewType === ViewType.TABLE ? 'red' : undefined,
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: 10 }}>
            {selectedRowKeys.length === 0 && (
              <>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {viewType === 'chart' && (
                    <>
                      <FilterDropdown
                        chartFilters={chartFilters}
                        setChartFilters={setChartFilters}
                        filterMenuVisible={filterMenuVisible}
                        setFilterMenuVisible={setFilterMenuVisible}
                        tableColumns={tableColumns}
                        chartData={chartData ?? []}
                        formulationsToFilter={formulationsToFilter}
                        setFormulationsToFilter={setFormulationsToFilter}
                        onClearFilters={handleClearFilters}
                      />
                      {chartFilters.length > 0 && (
                        <Button
                          type="link"
                          onClick={() => {
                            setChartFilters([]);
                            setFormulationsToFilter([]);
                          }}
                        >
                          Clear All
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            {selectedRowKeys.length >= 1 && (
              <>
                {user && !user.enableIceCreamBetaFeatures && (
                  <Button
                    disabled={!selectedRowKeys.length}
                    onClick={() => {
                      if (currentProject) {
                        const selectedFormulations = projectFormulations?.filter(
                          f => f?.key && selectedRowKeys.includes(f?.key)
                        );
                        const exportContent = getFormulationsExport(
                          roundFormulations(
                            selectedFormulations ?? [],
                            currentProject?.valuePrecision
                          ),
                          {
                            removeOutcomeValues: true,
                            includeOutcomeBounds: true,
                            outcomes: currentProject?.activeModel
                              ?.outcomes as Outcome[],
                          }
                        );

                        logEvent(
                          TrackableEvent.DOWNLOADED_ADAPTIVE_LEARNING_RESULTS,
                          {
                            iterationID: selectedIteration?.id,
                          }
                        );

                        const downloadLink = document.createElement('a');
                        downloadLink.href = `data:attachment/text,${encodeURIComponent(
                          exportContent
                        )}`;
                        downloadLink.download = filename;
                        downloadLink.click();
                      }
                    }}
                  >
                    <span>{<DownloadOutlined />} Test Plan</span>
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div>
        {viewType === ViewType.TABLE && (
          <ExperimentListTableComponentV2
            tableKey={tableKey}
            isolatedRows={isolatedRows}
            setIsolatedRows={setIsolatedRows}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            tableData={tableData}
            setTableData={setTableData}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
            sortBy={sortBy}
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            resetAllTable={resetAllTable}
            selectedScenarios={selectedScenarios}
            setSelectedScenarios={setSelectedScenarios}
            setSortBy={setSortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            viewType={viewType}
            chartFilters={chartFilters}
            setChartFilters={setChartFilters}
            filterMenuVisible={filterMenuVisible}
            setFilterMenuVisible={setFilterMenuVisible}
            chartData={chartData ?? []}
            formulationsToFilter={formulationsToFilter}
            setFormulationsToFilter={setFormulationsToFilter}
            handleIsolateTableRows={handleIsolateTableRows}
            setShowDeleteFormulationModal={setShowDeleteFormulationModal}
            tableDataForFilter={tableDataForFilter}
            showCopilot={showCopilot}
            setShowCopilot={setShowCopilot}
          />
        )}
        {viewType === ViewType.CHART &&
          formulationsToFilter.length !== chartData?.[0]?.values.length && (
            <div
              style={{
                width: '100%',
                overflowX: 'scroll',
                overflowY: 'hidden',
              }}
            >
              {chartData?.map(({ name: keyName, values }) => {
                const column = tableColumns.find(tc => tc.key === keyName);
                return column?.sourceRecord?.type === VariableType.NUMERIC &&
                  column.visible &&
                  formulationsToFilter.length !== values.length ? (
                  <ExperimentBarChart
                    data={values.filter(
                      formulation =>
                        !formulationsToFilter?.includes(formulation.name)
                    )}
                    keyName={keyName}
                    columnData={column}
                    moveChart={moveChart}
                  />
                ) : null;
              })}
            </div>
          )}
        {formulationsToFilter.length === chartData?.[0]?.values.length && (
          <div style={{ width: '100%', overflow: 'hidden' }}>
            <Empty>
              <Button
                type="primary"
                onClick={() => {
                  setChartFilters([]);
                  setFormulationsToFilter([]);
                  setSelectedScenarios([]);
                }}
              >
                Clear Filters
              </Button>
            </Empty>
          </div>
        )}
      </div>

      <UploadResultsDrawer
        open={openUploadResultsDrawer}
        onClose={handleUploadResultsDrawerClose}
      />
      <Modal
        open={showDeleteFormulationModal}
        title="Are you sure?"
        onCancel={() => setShowDeleteFormulationModal(false)}
        onOk={() => handleDeleteManyFormulations()}
      >
        <Typography.Text>
          Deleting these formulations will permanently remove them from the
          system and cannot be reversed.
        </Typography.Text>
        {view === 'testPlan' && (
          <Typography.Text strong>
            This will trigger an update to your project.
          </Typography.Text>
        )}
      </Modal>
    </div>
  );
};

export default ExperimentListLayoutV2;
