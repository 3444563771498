import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  getExportFile: (org_id: string, proj_id: string) =>
    `org/${org_id}/export/${proj_id}`,
};


const getExportFile = (parameters: any) => {
  return BaseClient.get(ENDPOINTS.getExportFile(parameters.queryKey[1], parameters.queryKey[2]))
}

export const ExportRepository = {
  getExportFile
};