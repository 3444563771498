import { useMutation } from 'react-query';
import { LunaChatRepository } from '../repositories/luna-chat.repository';

export const useSendLunaChatMessage = () => {
  const mutation = useMutation(
    (data: {
      organizationId: string;
      projectId: string;
      message: string;
      userId: string;
    }) => {
      return LunaChatRepository.postSendMessage(data);
    }
  );
  return mutation;
};
