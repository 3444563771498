/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Spin, Table, TableProps, Tag, Tooltip } from 'antd';

import { CostOptimizationOption, FormulationItemType, ObjectiveType } from '@prisma/client';
import { Formulation, FormulationItem, IngredientList, Objective } from '__generated__/globalTypes';
import _ from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { EditableCell } from './editable-cell';
import { useGetFormulationGoal } from '../../../network/services/goal.service';
import { FormulationSelected } from '../formulation-item/formulation-item'
import { FormulationType } from '../../../_shared/context/formulations-context';
import { useSession } from '../../../_shared/context';
import { calculateFormulationCostV2, validateGoalSimulatorBounds } from '../../../_shared/utils/util';
import './table-desirability-formulation.less'
import { parseBenchkmarkRowData, parseDesirabilityPerFormulation, parseDesirabilityPerOutcomeFormulation } from './table-desirability.utils';
import { useTranslation } from 'react-i18next';

export const TableDesirabilityFormulation = ({ goalDesirability, goalSimulator, perOutcome, title }: { goalDesirability: any, goalSimulator: any, perOutcome: boolean, title: string }) => {
  const { t } = useTranslation();
  const { user, currentProject } = useSession();
  const [columnsFormulationDesirability, setColumnsFormulationDesirability] = useState<any[]>([]);
  const [columnsDataFormulationDesirability, setColumnsDataFormulationDesirability] = useState<any[]>([]);
  const [formulationParams, setFormulationParams] = useState<any>({
    organizationId: undefined, projectId: undefined, formId: undefined
  });
  const [formSelected, setFormSelected] = useState<FormulationType>();
  const [showFormSelected, setShowFormSelected] = useState<boolean>(false);
  const [tableKey, setTableKey] = useState(0);
  const [selectedColumnKey, setSelectedColumnKey] = useState<string>('');
  const [targetCost, setTargetCost] = useState({ targetCost: false, targetCostValue: 0 });

  const { data: formulation, isSuccess, refetch: getFormulation, isLoading } = useGetFormulationGoal(formulationParams);

  const defineUserObjective = (objective: Objective) => {
    switch (objective.objectiveType) {
      case ObjectiveType.IN_RANGE: return `${objective.lower} - ${objective.upper}`;
      case ObjectiveType.MAXIMIZE: return `Maximize`;
      case ObjectiveType.MINIMIZE: return `Minimize`;
      case ObjectiveType.TARGET_VALUE: return `${objective.value}`;
    }
  }

  const isValueInRange = (value: number, objective: any) => {
    return value >= objective.minTarget && value <= objective.maxTarget;
  }

  const handleRightPanel = async (key: string, record: any) => {
    setFormulationParams({
      organizationId: user?.organizationId, projectId: currentProject?.id, formId: record[`${key}-formId`]
    })
    await getFormulation();
    setSelectedColumnKey(key);
  }

  const refreshTable = () => {
    setTableKey(prevKey => prevKey + 1);
  };

  useEffect(() => {
    console.log(formulation)
    if (formulation) {
      setFormSelected(formulation.data)
      setShowFormSelected(true)
    }
  }, [formulation, formulation?.data, isSuccess])

  useEffect(() => {
    if (!showFormSelected) {
      setSelectedColumnKey('')
    }
  }, [showFormSelected])

  const calculateWidthFromValues = (data: any[], dataIndex: string, padding: number = 0) => {
    const avgCharWidth = 9;
    const maxLength = Math.max(
      ...data.map(item => String(item[dataIndex]).length),
    );

    return maxLength * avgCharWidth + padding;
  };

  useEffect(() => {
    if (goalDesirability) {
      let valid = false;
      if (goalSimulator)
        valid = validateGoalSimulatorBounds(goalSimulator)
      const data = goalDesirability.data;
      const objectives = data.objectives?.objectives;
      const benchmark: Formulation = data.benchmark
      const ingredientList = currentProject?.ingredientList as
        | IngredientList[]
        | undefined;
      if (objectives) {

        const formulationsDesing = data.formulations;
        const rows: any[] = []
        const rowCost: any = {
          "name": 'Cost',
          "importance": undefined,
          "userObjective": '',
          "userTargetObjective": undefined,
          "initiative": {}
        }
        let calculateCost = true;

        const lastInitiativeKey = Object.keys(formulationsDesing).pop();
        const lastInitiative = lastInitiativeKey ? formulationsDesing[lastInitiativeKey] : null;
        const lastFormulation = lastInitiative ? lastInitiative[0] : null;

        const tableList = objectives.map((objective: (Objective & { outcomeId: string })) => {
          let row: any = {};
          row['name'] = objective.targetVariable;
          row['importance'] = objective.importance;
          row['userObjective'] = defineUserObjective(objective);
          row['userTargetObjective'] = objective//`${objective.minTarget ?? 'N/A'} - ${objective.maxTarget ?? 'N/A'}`;

          const benchmarkRowData = parseBenchkmarkRowData(benchmark, objective, calculateCost, currentProject);
          if (benchmark)
            row['benchmark'] = benchmarkRowData.benchmark;
          const formulationRowData = perOutcome ? parseDesirabilityPerOutcomeFormulation(formulationsDesing, objective, calculateCost, currentProject) :
            parseDesirabilityPerFormulation(formulationsDesing, objective, calculateCost, currentProject);
          row['initiative'] = formulationRowData.initiative;
          if (goalSimulator && goalSimulator?.desirabilityByRound && valid) {
            let forceastedInitiative: any = {}
            const desirabilityByRound = goalSimulator?.desirabilityByRound;
            for (let forecastRound of Object.keys(desirabilityByRound)) {

              const outcome = desirabilityByRound[forecastRound].outcome_values[objective.targetVariable]?.[0];
              if (outcome !== undefined) {
                forceastedInitiative[forecastRound] = outcome[1].toFixed(3);
              }
            }
            row['forecasted'] = { forceastedInitiative };
          }
          if (calculateCost) {
            calculateCost = false
            rowCost['initiative'] = formulationRowData.costValues;
            if (benchmark) {
              rowCost['benchmark'] = benchmarkRowData.benchmarkCost?.toFixed(3)
            }
            if (data.objectives?.costOptimizationOption === CostOptimizationOption.DO_NOT_OPTIMIZE) {
              if (benchmark)
                setTargetCost({ targetCost: true, targetCostValue: benchmarkRowData.benchmarkCost });

            } else {
              setTargetCost({ targetCost: true, targetCostValue: data.objectives?.nteCost });
            }


            rows.push(rowCost)
          }

          rows.push(row);
        })

        if (lastFormulation && formulationParams.formId === undefined) {
          setFormulationParams({
            organizationId: user?.organizationId,
            projectId: currentProject?.id,
            formId: lastFormulation.formulationItems[0].formulationId,
          });
          getFormulation();
          setShowFormSelected(true);
          setSelectedColumnKey(`${lastFormulation.initiative}-${lastFormulation.round + 1}`);
        }

        if (rows.length > 0) {
          const first = rows[1]
          const columns: TableProps<any>['columns'] = [
            {
              title: 'Outcome',
              dataIndex: 'name',
              key: 'name',
              fixed: 'left',
              width: calculateWidthFromValues(rows, 'name'),
            }, {
              title: 'Priority',
              dataIndex: 'importance',
              key: 'importance',
              fixed: 'left',
              width: calculateWidthFromValues(rows, 'importance'),
              render: (value) => {
                let color: any = {
                  0: 'default',
                  1: 'processing',
                  2: 'success',
                  3: 'error'
                };
                let tags: any = {
                  default: 'None',
                  processing: 'Low',
                  success: 'Medium',
                  error: 'High'
                }
                return value !== undefined && <Tag color={color[value as number]} key={tags[color[value]]}>
                  {tags[color[value]]}
                </Tag>
              }
            },
            {
              title: () => <Tooltip placement="top" title={`Goals at the time when the initiative was generated`}>Project Goal <InfoCircleOutlined /></Tooltip>,
              dataIndex: 'userObjective',
              key: 'userObjective',
              fixed: 'left',
              width: 140,
            },
            {
              title: () => <Tooltip placement="top" title={
                <div>
                  <p>The acceptable range values are not stored by the web app. Please reload the page to reset them.</p>
                  <p>Highlighted values indicates value is under acceptable range</p>
                </div>
              }>Acceptable Range <InfoCircleOutlined /></Tooltip>,
              dataIndex: "userTargetObjective",
              key: "userTargetObjective",
              fixed: 'left',
              width: 10,
              render: (vale, record) => {
                return vale && <EditableCell value={record['userTargetObjective']} onRefreshTable={refreshTable} />
              }
            }

          ]
          if (benchmark) {
            columns.push({
              title: `Benchmark`,
              dataIndex: "benchmark",
              key: "benchmark",
              fixed: 'left',
              width: 10
            })
          }

          for (let iname of Object.keys(first.initiative)) {
            columns.push({
              title: iname,
              dataIndex: iname,
              key: iname,
              children: Object.keys(first.initiative[iname]).map((round) => {
                return {
                  title: `Round ${round}`,
                  dataIndex: `${iname}-${round}`,
                  key: `${iname}-${round}`,
                  width: 150,
                  render: (value, record) => {
                    const cost = targetCost.targetCostValue ? value * 100 / targetCost.targetCostValue : 0
                    return {
                      props: {},
                      children:
                        (record.name === 'Cost') ?
                          targetCost.targetCost ? <Tooltip placement="top" title={<div className={'look-formulation'} >{`${cost.toFixed(2)}% from ${data.objectives?.costOptimizationOption === CostOptimizationOption.DO_NOT_OPTIMIZE ? `benchmark` : `cost optimization`} target value`}</div>} >
                            <Tag>
                              {value}
                            </Tag>

                          </Tooltip> :

                            <Tag>{value}</Tag>
                          :
                          <Tooltip placement="top" title={<div className={'look-formulation'} >Click on the cell to see the formulation related</div>} >
                            <Tag color={
                              perOutcome ?
                                (record['best-desirability'] === `${iname}-${round}`) ?
                                  'green'
                                  : record.userTargetObjective !== undefined &&
                                    isValueInRange(
                                      value,
                                      record.userTargetObjective
                                    )
                                    ? 'blue'
                                    : 'default'

                                : (record.userTargetObjective !== undefined && isValueInRange(value, record.userTargetObjective)) ?
                                  "blue"
                                  : "default"
                            }
                              key={`${iname}-${round}`}
                              className={
                                selectedColumnKey === `${iname}-${round}`
                                  ? "selectedColumn"
                                  : ""
                              }
                              onClick={(e) => {
                                if (!showFormSelected) setShowFormSelected(true)
                                handleRightPanel(`${iname}-${round}`, record)
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {value}
                            </Tag>
                          </Tooltip>
                    }
                  }
                }
              })
            })
          }

          if (goalSimulator && goalSimulator?.desirabilityByRound && valid)
            columns.push({
              title: `Forecasted (Beta)`,
              dataIndex: `forceastedInitiative`,
              key: `forceastedInitiative`,
              className: `forecasted-column`,
              children: Object.keys(first.forecasted.forceastedInitiative).map((round) => {
                return {
                  title: round,
                  dataIndex: `forceastedInitiative-${round}`,
                  key: `forceastedInitiative-${round}`,
                  className: `forecasted-column`,
                  render: (value, record) => {
                    return {
                      props: {},
                      children:
                        <Tag color={
                          record.userTargetObjective !== undefined && isValueInRange(value, record.userTargetObjective) ? "blue" : "default"}
                          key={`forceastedInitiative-${round}`}
                          className={
                            selectedColumnKey === `forceastedInitiative-${round}`
                              ? "selectedColumn"
                              : ""
                          }
                        >
                          {value}
                        </Tag>
                    }
                  }
                }
              })
            })
          if (goalSimulator && goalSimulator?.desirabilityByRound && !valid) {
            columns.push({
              title: `Forecasted (Beta)`,
              dataIndex: `forceastedInitiative`,
              key: `forceastedInitiative`,
              className: `forecasted-column`,
              render: (text, record, index) => {

                return {
                  children: index === 0 ? <Tag color={"default"}
                    key={`forceastedInitiative`}
                  >
                    {text}
                  </Tag> : null,
                  props: {
                    rowSpan: index === 0 ? objectives?.length : 0,
                  },
                };
              },
            })
          }

          setColumnsFormulationDesirability(columns)

          //flattening data
          let flatterData;
          if (perOutcome) {
            flatterData = rows.map(row => {
              let result: any = {
                name: row.name,
                userObjective: row.userObjective,
                userTargetObjective: row.userTargetObjective,
                importance: row.importance,
                benchmark: row.benchmark
              };
              let bestDesirability = {
                name: '',
                desirability: -1,
              };
              for (let iname of Object.keys(first.initiative)) {
                if (row.initiative[iname])
                  Object.keys(row.initiative[iname]).map(round => {
                    result[`${iname}-${round}-formId`] =
                      row.initiative[iname][round].formulationId;
                    result[`${iname}-${round}`] =
                      row.initiative[iname][round].formulationItem;
                    if (
                      bestDesirability.desirability <
                      row.initiative[iname][round].desirability
                    ) {
                      bestDesirability.name = `${iname}-${round}`;
                      bestDesirability.desirability =
                        row.initiative[iname][round].desirability;
                    }
                  });
              }
              result['best-desirability'] = bestDesirability.name;
              if (goalSimulator && valid && row.forecasted)
                Object.keys(row.forecasted.forceastedInitiative).map((round) => {
                  result[`forceastedInitiative-${round}`] = row.forecasted.forceastedInitiative[round]
                })
              if (goalSimulator && !valid)
                result['forceastedInitiative'] = t('execute-solution.page.projectGoalManagerTab.messages.unableForecastDueData')
              return result;

            });
          } else {
            flatterData = rows.map((row) => {
              let result: any = {
                name: row.name,
                userObjective: row.userObjective,
                userTargetObjective: row.userTargetObjective,
                importance: row.importance,
                formulationId: row.formulationId,
                benchmark: row.benchmark
              }
              for (let iname of Object.keys(first.initiative)) {
                if (row.initiative[iname])
                  Object.keys(row.initiative[iname]).map((round) => {
                    result[`${iname}-${round}`] = row.initiative[iname][round].formulationItem
                    result[`${iname}-${round}-formId`] = row.initiative[iname][round].formulationId
                  })
              }
              if (goalSimulator && valid && row.forecasted)
                Object.keys(row.forecasted.forceastedInitiative).map((round) => {
                  result[`forceastedInitiative-${round}`] = row.forecasted.forceastedInitiative[round]
                })
              if (goalSimulator && !valid)
                result['forceastedInitiative'] = t('execute-solution.page.projectGoalManagerTab.messages.unableForecastDueData')
              return result;
            })
          }

          setColumnsDataFormulationDesirability(_.orderBy(flatterData, 'importance', 'desc'))



        }
      }
    }
  }, [goalDesirability, selectedColumnKey, goalSimulator])

  return (
    <>
      <h2 >{title}</h2>
      <div className='table-panel'>
        <div className='table-container'>
          <Table key={tableKey} dataSource={columnsDataFormulationDesirability} columns={columnsFormulationDesirability} scroll={{ x: 'max-content' }} />
        </div>
        {(formSelected && showFormSelected) && (
          <div className='formulation-container'>
            <FormulationSelected formulation={formSelected} onHandleFormulation={setShowFormSelected} />
          </div>)}
      </div>

    </>


  );
};
