import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  postImportFile: (org_id: string) =>
    `org/${org_id}/import/`,
};

const postImportFile = (org_id: string, data: any) => {
  return BaseClient.post(ENDPOINTS.postImportFile(org_id), data);
}

export const ImportRepository = {
  postImportFile
};