import React, { useState } from 'react';
import { Modal, Upload, message, Button, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

interface ProjectImportDialogProps {
  visible: boolean;
  onCancel: () => void;
  importProject: (data: any) => void;
}

export const ProjectImportDialog: React.FC<ProjectImportDialogProps> = ({
  visible,
  onCancel,
  importProject,
}) => {
  const [jsonData, setJsonData] = useState<any>(null);

  const handleFileUpload = (file: any) => {
    const isJson = file.type === 'application/json';
    if (!isJson) {
      message.error('You can only upload JSON files!');
      return false;
    }

    const reader = new FileReader();
    reader.onload = () => {
      try {
        const parsedData = JSON.parse(reader.result as string);
        setJsonData(parsedData);
        const validatorResult = importFileValidator(parsedData);
        if (validatorResult.hasMissingAttributes) {
          throw new Error(
            `Required attribute(s): "${validatorResult.missingAttributes}" not found on import file`
          );
        }

        message.success('File uploaded successfully!');
        importProject(parsedData);
        onCancel();
      } catch (error) {
        if (error) {
          const errMessage = (error as Error).message;
          notification.error({
            message: errMessage,
          });
        } else {
          message.error('Failed to parse the JSON file!');
        }
        onCancel();
      }
    };
    reader.readAsText(file);

    return false;
  };

  const importFileValidator = (data: any) => {
    let hasMissingAttributes = false;
    const missingAttributes = [];

    const requiredAttributes = [
      'projectStartData',
      'projectUploadData',
      'projectInputOutput',
      'projectNNConstraint',
      'projectModelPriorId',
      // 'projectIngredienComposition',
      // 'projectIngredientGroup'
      // 'projectDesigns'
    ];

    for (const attribute of requiredAttributes) {
      if (!(attribute in data)) {
        hasMissingAttributes = true;
        missingAttributes.push(attribute);
      }
    }

    return {
      hasMissingAttributes,
      missingAttributes,
    };
  };

  return (
    <Modal
      title="Import Project"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={600}
    >
      <Upload.Dragger
        name="file"
        multiple={false}
        showUploadList={false}
        beforeUpload={handleFileUpload}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined
            style={{
              color: 'rgb(239, 65, 54)',
            }}
          />
        </p>
        <p className="ant-upload-text">
          Click or drag your .JSON file here to import a project.
        </p>
        <p className="ant-upload-text">
          Upload a .JSON export file to start the import process.
        </p>
      </Upload.Dragger>

      {/* {jsonData && (
        <div style={{ marginTop: 16 }}>
          <h3>Parsed JSON Data:</h3>
          <pre>{JSON.stringify(jsonData, null, 2)}</pre>
        </div>
      )} */}
    </Modal>
  );
};
