import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import React from "react";

export const SearchBar = ({
  data,
  onSearch,
}: {
  data: (string | null | undefined)[];
  onSearch: (searchTerm: string) => void;
}) => {
  const [searchInput, setSearchInput] = React.useState('');

  const handleInputChange = (value: string) => {
    setSearchInput(value);
    onSearch(value);
  };

  const handleClearInput = () => {
    setSearchInput('');
    onSearch('');
  };

  // Opciones del dropdown basadas en la búsqueda
  const filteredOptions = data
    .filter(item => item.toLowerCase().includes(searchInput.toLowerCase()))
    .map(item => ({
      value: item,
    }));

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <AutoComplete
        options={filteredOptions}
        value={searchInput}
        onChange={handleInputChange}
        style={{ width: '100%' }}
        filterOption={(inputValue, option) => {
          if (!option?.value) return false;
          return option.value.toLowerCase().includes(inputValue.toLowerCase());
        }}
      >
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search formulations..."
          suffix={
            searchInput && (
              <CloseCircleOutlined
                onClick={handleClearInput}
                style={{ cursor: 'pointer' }}
              />
            )
          }
        />
      </AutoComplete>
    </div>
  );
};