import { useCallback, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { useExecuteSolutions, useSession } from '../context';
import { IMessage } from '../types';
import { notification } from 'antd';
import { useFormulations } from '../context/formulations-context';
import { useScenarioDetail } from '../context/scenario-detail-context';
import { handleInitiativeErrors } from '../utils/handleInitiativeErrors';

const SOCKET_URL = __BASE_URL__;

const useWebSocket = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnecting, setIsConnecting] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [messages, setMessages] = useState<IMessage>();
  const [goalSimulationFinished, setGoalSimulationFinished] = useState<
    boolean
  >();
  const { user, setLoadFolder } = useSession();
  const [loadTestPlan, setLoadTestPlan] = useState<string>();
  const [loadTestPlanError, setLoadTestPlanError] = useState<string>('');
  const [loadIterationFormulations, setLoadIterationFormulations] = useState<
    boolean
  >(false);
  const { refreshFeedbackFormulations } = useFormulations();
  const [refresh, setRefresh] = useState<boolean>(false);

  const handleToggle = () => {
    setRefresh((prevState) => !prevState);
  };

  useEffect(() => {
    const socketInstance = io(SOCKET_URL, {
      transports: ['websocket'],
    });

    setSocket(socketInstance);

    socketInstance.on('connect', () => {
      setIsConnected(true);
      setIsConnecting(false);
      setError(null);
    });

    socketInstance.on('messageFromServer', message => {
      if (message.userId === user?.id) {
        setMessages(message);
      }
    });

    socketInstance.on('goalSimulationFinished', message => {
      setGoalSimulationFinished(
        goalSimulationFinished ? !goalSimulationFinished : true
      );
    });

    socketInstance.on('endOfRound', message => {
      if (!message.error) {
        setLoadTestPlan(message.message);
        setLoadFolder(true);
        setLoadTestPlanError('');
        handleToggle();
      } else {
        setLoadTestPlanError(message.message);
        handleToggle();
        // setLoadTestPlanError("test")
        // notification.error({ message: message.message, duration: null });
      }
    });

    socketInstance.on('softDeletedCampaign', message => {
      if (!message.error) {
        setLoadFolder(true);
        setLoadTestPlan(message.message);
        refreshFeedbackFormulations();
      } else {
        notification.error({ message: message.message });
      }
    });

    socketInstance.on('disconnect', () => {
      setIsConnected(false);
    });

    socketInstance.on('connect_error', (err: Error) => {
      setIsConnected(false);
      setIsConnecting(false);
      setError(`Connection Error: ${err.message}`);
    });

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, []);

  const emit = useCallback(
    (event: string, ...args: any[]) => {
      if (socket) {
        socket.emit(event, ...args);
      }
    },
    [socket]
  );



  return {
    socket,
    isConnected,
    isConnecting,
    error,
    emit,
    messages,
    goalSimulationFinished,
    loadTestPlan,
    loadIterationFormulations,
    loadTestPlanError,
    refresh
  };
};

export default useWebSocket;
