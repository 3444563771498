import { EyeOutlined, FolderOutlined, PlusOutlined } from '@ant-design/icons';
import {
  TestPlanWithFormulationsType,
  useExecuteSolutions,
  useSession,
} from '../../../_shared/context';
import { Button, Flex, Input, Menu, notification, Popover } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { DropTargetTestPlanComponentV2 } from './drop-target-test-plan-v2';
import React from 'react';
import styled from '@emotion/styled';
import { usecreateOneTestPlanMutation } from '../../../../../__generated__/globalTypes';
import { AddTestPlanInput } from './add-test-plan-input';

export const TestPlanDropdowns = ({
  testPlansWithFormulations,
  toggleTestPlan,
  collapsedTestPlans,
  handleOnFormulationDrop,
  handleTestPlanProcessingComplete,
  setTestPlanSummaryToShow,
  setFilterCurrentCampaign,
  filterCurrentCampaign,
  campaignFilteredId,
  setCampaignFilteredId,
  roundSelectedId,
  setRoundSelectedId,
  customTestPlanFilteredId,
  setCustomTestPlanFilteredId,
}: {
  testPlansWithFormulations: TestPlanWithFormulationsType[];
  toggleTestPlan: (testPlanId: string) => void;
  collapsedTestPlans: Record<string, boolean>;
  handleOnFormulationDrop: (
    formulationKey: string,
    testPlanId: string,
    action: 'ADD' | 'REMOVE'
  ) => void;
  handleTestPlanProcessingComplete: (
    updatedTestPlan: TestPlanWithFormulationsType
  ) => void;
  setTestPlanSummaryToShow: any;
  setFilterCurrentCampaign: React.Dispatch<React.SetStateAction<boolean>>;
  filterCurrentCampaign: boolean;
  campaignFilteredId: String | undefined;
  setCampaignFilteredId: Dispatch<React.SetStateAction<String | undefined>>;
  roundSelectedId: String | undefined;
  setRoundSelectedId: Dispatch<React.SetStateAction<String | undefined>>;
  customTestPlanFilteredId: String | undefined;
  setCustomTestPlanFilteredId: Dispatch<
    React.SetStateAction<String | undefined>
  >;
}) => {
  const { currentProject } = useSession();
  const {
    selectedIteration,
    setTestPlansWithFormulations,
  } = useExecuteSolutions();
  const [createTestPlan, { loading }] = usecreateOneTestPlanMutation();
  const [initiativeOpenKeys, setInitiativeOpenKeys] = useState<string[]>([
    'test-plans',
  ]);
  const [customOpenKeys, setCustomOpenKeys] = useState<string[]>([
    'test-plans',
  ]);
  const [showNewTestPlanPopover, setShowNewTestPlanPopover] = useState(false);

  const testPlansWithCampaignId = testPlansWithFormulations.filter(
    testPlan => testPlan?.campaignId
  );
  const testPlansWithoutCampaignId = testPlansWithFormulations.filter(
    testPlan => !testPlan?.campaignId
  );

  const handleSubMenuClick = (key: string, folder: string) => {
    if (folder === 'initiatives') {
      if (initiativeOpenKeys.includes(key)) {
        setInitiativeOpenKeys(initiativeOpenKeys.filter(k => k !== key));
      } else {
        setInitiativeOpenKeys([...initiativeOpenKeys, key]);
      }
    }
    if (folder === 'custom') {
      if (customOpenKeys.includes(key)) {
        setCustomOpenKeys(customOpenKeys.filter(k => k !== key));
      } else {
        setCustomOpenKeys([...customOpenKeys, key]);
      }
    }
  };

  const handleCreateNewTestPlan = async (name: string) => {
    try {
      if (name === '') {
        notification.error({ message: 'Test Plan name is required' });
      } else {
        const createTestPlanResponse = await createTestPlan({
          variables: {
            iterationId: selectedIteration?.id!,
            name: name,
          },
        });

        if (createTestPlanResponse.data?.createOneTestPlan) {
          setTestPlansWithFormulations([
            ...testPlansWithFormulations,
            createTestPlanResponse.data.createOneTestPlan,
          ]);

          setShowNewTestPlanPopover(false);
        }
      }
    } catch (error) {
      notification.error({ message: 'Error creating Test Plan' });
    }
  };

  const CustomMenu = styled(Menu)`
    .ant-menu-submenu-title {
      padding-left: 5px !important;
    }
  `;

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '10px',
        }}
      >
        <Button
          type="text"
          icon={<EyeOutlined />}
          onClick={() =>
            campaignFilteredId
              ? (setCampaignFilteredId(undefined),
                setRoundSelectedId(undefined),
                filterCurrentCampaign
                  ? setFilterCurrentCampaign(false)
                  : undefined)
              : customTestPlanFilteredId
              ? setCustomTestPlanFilteredId(undefined)
              : setFilterCurrentCampaign(!filterCurrentCampaign)
          }
          disabled={
            currentProject?.currentCampaignId == undefined &&
            !campaignFilteredId
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '90%',
            border: 'solid 1px black',
          }}
        >
          {filterCurrentCampaign ||
          campaignFilteredId ||
          customTestPlanFilteredId
            ? 'Show All'
            : 'Show Current Initiative'}
        </Button>
      </div>
      <CustomMenu
        style={{ width: '100%', borderRadius: '20px' }}
        mode="inline"
        openKeys={initiativeOpenKeys}
        onOpenChange={setInitiativeOpenKeys}
      >
        <SubMenu
          key="test-plans"
          icon={<FolderOutlined />}
          title="Initiatives"
          onTitleClick={() => handleSubMenuClick('test-plans', 'initiatives')}
        >
          {testPlansWithCampaignId.map(testPlan => (
            <div style={{ paddingLeft: 24 }}>
              <DropTargetTestPlanComponentV2
                testPlan={testPlan}
                toggleTestPlan={toggleTestPlan}
                collapsedTestPlans={collapsedTestPlans}
                handleOnFormulationDrop={(formulationId: string) =>
                  handleOnFormulationDrop(formulationId, testPlan!.id, 'ADD')
                }
                handleRemoveFormulation={(formulationId: string) =>
                  handleOnFormulationDrop(formulationId, testPlan!.id, 'REMOVE')
                }
                handleTestPlanProcessingComplete={
                  handleTestPlanProcessingComplete
                }
                setTestPlanSummaryToShow={setTestPlanSummaryToShow}
                campaignFilteredId={campaignFilteredId}
                setCampaignFilteredId={setCampaignFilteredId}
                roundSelectedId={roundSelectedId}
                setRoundSelectedId={setRoundSelectedId}
                customTestPlanFilteredId={customTestPlanFilteredId}
                setCustomTestPlanFilteredId={setCustomTestPlanFilteredId}
              />
            </div>
          ))}
        </SubMenu>
      </CustomMenu>
      <CustomMenu
        style={{ width: '100%', borderRadius: '20px' }}
        mode="inline"
        openKeys={customOpenKeys}
        onOpenChange={setCustomOpenKeys}
      >
        <SubMenu
          key="test-plans"
          icon={<FolderOutlined />}
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>Custom</span>
              <Popover
                open={showNewTestPlanPopover}
                content={
                  <div onClick={e => e.stopPropagation()}>
                    <AddTestPlanInput
                      onAddTestPlan={name => handleCreateNewTestPlan(name)}
                    />
                  </div>
                }
                title="Create a Test Plan"
                trigger="click"
              >
                <div onClick={e => e.stopPropagation()}>
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      setShowNewTestPlanPopover(!showNewTestPlanPopover)
                    }
                  >
                    Add
                  </Button>
                </div>
              </Popover>
            </div>
          }
          onTitleClick={() => handleSubMenuClick('test-plans', 'custom')}
        >
          {testPlansWithoutCampaignId.map(testPlan => (
            <div style={{ paddingLeft: 24 }}>
              <DropTargetTestPlanComponentV2
                key={testPlan?.id}
                testPlan={testPlan}
                toggleTestPlan={toggleTestPlan}
                collapsedTestPlans={collapsedTestPlans}
                handleOnFormulationDrop={(formulationId: string) =>
                  handleOnFormulationDrop(formulationId, testPlan!.id, 'ADD')
                }
                handleRemoveFormulation={(formulationId: string) =>
                  handleOnFormulationDrop(formulationId, testPlan!.id, 'REMOVE')
                }
                handleTestPlanProcessingComplete={
                  handleTestPlanProcessingComplete
                }
                setTestPlanSummaryToShow={setTestPlanSummaryToShow}
                campaignFilteredId={campaignFilteredId}
                setCampaignFilteredId={setCampaignFilteredId}
                roundSelectedId={roundSelectedId}
                setRoundSelectedId={setRoundSelectedId}
                customTestPlanFilteredId={customTestPlanFilteredId}
                setCustomTestPlanFilteredId={setCustomTestPlanFilteredId}
              />
            </div>
          ))}
        </SubMenu>
      </CustomMenu>
    </>
  );
};
