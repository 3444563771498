import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  postSendMessage: (org_id: string, project_id: string) =>
    `org/${org_id}/project/${project_id}/luna/chat/message`,
};

const postSendMessage = (data: {
  organizationId: string;
  projectId: string;
  message: string;
  userId: string;
}) => {
  return BaseClient.post(
    ENDPOINTS.postSendMessage(data.organizationId, data.projectId),
    {
      message: data.message,
      user_id: data.userId,
    }
  );
};

export const LunaChatRepository = {
  postSendMessage,
};
