/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  matchPath,
  createSearchParams,
} from 'react-router-dom';
import {
  sidebarSectionStyle,
  sidebarTitleStyle,
} from '../../../../../components/project/project-sider-v2.styles';
import { reportItemStyle } from './main-routes-sidebar.styles';
import {
  ProjectFeature,
  WorkspaceType,
} from '../../../../../../../__generated__/globalTypes';
import { IngredientCompositionSetupModal } from '../../../../../components/ingredient-composition';
import { useSession } from '../../../../../_shared/context';
import { useFeatureContext } from '../../../../../_shared/context/features-context';
import { ProductFeatureType } from '@prisma/client';
import { ExportRepository } from '../../../../../network/repositories/export.repository';
import { useGetExportFile } from '../../../../../network/services/export.service';
import { ExportOutlined } from '@ant-design/icons';

enum ReportType {
  Dashboard = 1,
  KnowledgeGraph = 2,
  SummaryView = 3,
  IngredientComposition = 4,
  ExpertInsight = 5,
  BasicProjectInfo = 6,
  DiscoverSolutions = 7,
  ExecuteSolutions = 8,
  TuringUniversity = 9
}

export const MainRoutesSidebar = ({
  selected = false,
  onSelect,
}: {
  selected: boolean;
  onSelect: () => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentProject, insightExplorerVersion, user } = useSession();
  const [selectedReport, setSelectedReport] = useState<ReportType>(
    ReportType.Dashboard
  );

  const [showKnowledgeGraph, setShowKnowledgeGraph] = useState(false);
  const { isEnableFeature } = useFeatureContext();
  const [showIngredientComposition, setShowIngredientComposition] = useState(
    false
  );
  const [defaultExplorationIteration, setDefaultExplorationIteration] = useState<any>();

  const featureToggles = new Set(
    currentProject?.features?.map(f => f.feature) || []
  );

  const { isSuccess: isSuccessExportFileQuery, isError: isErrorExprotFileQuery, data: exportFile, refetch: refetchExportFile } = useGetExportFile({
    organizationId: currentProject?.organizationId ?? '',
    projectId: currentProject?.id ?? ''
  });

  const routeMatches = [
    {
      path: `explore/*`,
      report: ReportType.DiscoverSolutions,
    },
    {
      path: `experiment/*`,
      report: ReportType.ExecuteSolutions,
    },
    {
      path: `experiments`,
      report: ReportType.ExecuteSolutions,
    },
    {
      path: `history`,
      report: ReportType.ExecuteSolutions,
    },
    {
      path: `overview`,
      report: ReportType.ExecuteSolutions,
    },
    { path: '', report: ReportType.Dashboard },
    {
      path: `details`,
      report: ReportType.BasicProjectInfo,
    },
    {
      path: 'turing-university',
      report: ReportType.TuringUniversity
    }
  ];

  useEffect(() => {
    const matchedRoute = routeMatches.find(({ path }) =>
      matchPath(`/project/:projectId/${path}`, location.pathname)
    );

    if (matchedRoute) {
      setSelectedReport(matchedRoute.report);
    } else {
      console.log('Route not found in sidebar.');
    }
  }, [location.pathname]);

  const isRouteActive = (path: string) => {
    return location.pathname.includes(path);
  };

  const navigateToExplore = (tab: string) => {
    const url = `/project/${currentProject?.id}/explore/${defaultExplorationIteration}/${tab}`;
    navigate(url);
    setSelectedReport(ReportType.DiscoverSolutions);
    onSelect();
  };

  const handleGoalSettingClick = () => navigateToExplore('goal');
  const handleInputConstraintsClick = () => navigateToExplore('constraints');
  const handleNotesClick = () => navigateToExplore('notes');

  useEffect(() => {
    setDefaultExplorationIteration(currentProject?.iterations?.filter(
      i => i.type === WorkspaceType.EXPLORATION
    )[0]?.id);
  }, [currentProject?.iterations])

  useEffect(() => {
    if (isSuccessExportFileQuery && exportFile) {
      const json = exportFile.data

      const jsonString = JSON.stringify(json, null, 2);

      const blob = new Blob([jsonString], { type: 'application/json' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${currentProject?.id ?? 'exported'}.json`;

      link.click();
    }
  }, [exportFile])

  return (
    <span>
      <div>
        <div css={sidebarSectionStyle} style={{ marginTop: '10px' }}>
          <div css={sidebarTitleStyle}>
            <div>Project Setup</div>
          </div>
        </div>
        <div
          css={reportItemStyle}
          role="button"
          tabIndex={0}
          className={
            isRouteActive(`/project/edit/${currentProject?.id}/info`)
              ? 'selected'
              : ''
          }
          onClick={() => navigate(`/project/edit/${currentProject?.id}/info`)}
        >
          Project Details
        </div>
        <div
          css={reportItemStyle}
          role="button"
          tabIndex={0}
          className={
            isRouteActive(`/project/inspect/${currentProject?.id}/upload`)
              ? 'selected'
              : ''
          }
          onClick={() =>
            navigate(`/project/inspect/${currentProject?.id}/upload`)
          }
        >
          Past Data Upload
        </div>
        <div
          css={reportItemStyle}
          role="button"
          tabIndex={0}
          className={
            isRouteActive(`/project/inspect/${currentProject?.id}/data`)
              ? 'selected'
              : ''
          }
          onClick={() =>
            navigate(`/project/inspect/${currentProject?.id}/data`)
          }
        >
          Visualize Data
        </div>
        <div
          css={reportItemStyle}
          role="button"
          tabIndex={0}
          className={
            isRouteActive(
              `/project/inspect/${currentProject?.id}/inputs-outcomes`
            )
              ? 'selected'
              : ''
          }
          onClick={() =>
            navigate(`/project/inspect/${currentProject?.id}/inputs-outcomes`)
          }
        >
          Inputs & Outcomes
        </div>
        <div
          css={reportItemStyle}
          role="link"
          tabIndex={-1}
          className={
            selected && ReportType.IngredientComposition === selectedReport
              ? 'selected'
              : ''
          }
          onClick={() => {
            setShowIngredientComposition(true);
            onSelect();
          }}
        >
          Ingredient Composition
        </div>
        <div
          css={reportItemStyle}
          role="button"
          tabIndex={0}
          className={
            isRouteActive(`/project/inspect/${currentProject?.id}/constraints`)
              ? 'selected'
              : ''
          }
          onClick={() =>
            navigate(`/project/inspect/${currentProject?.id}/constraints`)
          }
        >
          Non-negotiable Constraints
        </div>
        {
          isEnableFeature[ProductFeatureType.IMPORT_EXPORT_PROJECT] && (
            <div
              css={reportItemStyle}
              role="button"
              tabIndex={0}
              onClick={() =>
                refetchExportFile()
              }
            >
              Export Project
            </div>
          )
        }
        <div css={sidebarSectionStyle} style={{ marginTop: '10px' }}>
          <div css={sidebarTitleStyle}>
            <div>Discover Solutions</div>
          </div>
        </div>
        <div
          css={reportItemStyle}
          role="button"
          tabIndex={0}
          className={
            isRouteActive(
              `/project/${currentProject?.id}/explore/${defaultExplorationIteration}/goal`
            )
              ? 'selected'
              : ''
          }
          onClick={handleGoalSettingClick}
        >
          Goal Setting
        </div>
        <div
          css={reportItemStyle}
          role="button"
          tabIndex={0}
          className={
            isRouteActive(
              `/project/${currentProject?.id}/explore/${defaultExplorationIteration}/constraints`
            )
              ? 'selected'
              : ''
          }
          onClick={handleInputConstraintsClick}
        >
          Input Constraints
        </div>
        <div
          css={reportItemStyle}
          role="button"
          tabIndex={0}
          className={
            isRouteActive(
              `/project/${currentProject?.id}/explore/${defaultExplorationIteration}/notes`
            )
              ? 'selected'
              : ''
          }
          onClick={handleNotesClick}
        >
          Notes
        </div>
        <div css={sidebarSectionStyle} style={{ marginTop: '10px' }}>
          <div css={sidebarTitleStyle}>
            <div>Execute Solutions</div>
          </div>
        </div>
        <div
          css={reportItemStyle}
          role="button"
          tabIndex={0}
          className={
            isRouteActive(`/project/${currentProject?.id}/experiments`)
              ? 'selected'
              : ''
          }
          onClick={() => navigate(`/project/${currentProject?.id}/experiments`)}
        >
          Experiments & Initiatives
        </div>
        <div
          css={reportItemStyle}
          role="button"
          tabIndex={0}
          className={
            isRouteActive(`/project/${currentProject?.id}/history`)
              ? 'selected'
              : ''
          }
          onClick={() => navigate(`/project/${currentProject?.id}/history`)}
        >
          History
        </div>
        <div
          css={reportItemStyle}
          role="button"
          tabIndex={0}
          className={
            isRouteActive(`/project/${currentProject?.id}/goal_management_dashboard`)
              ? 'selected'
              : ''
          }
          onClick={() => navigate(`/project/${currentProject?.id}/goal_management_dashboard`)}
        >
          Goal Management Dashboard (Beta)
        </div>
        {isEnableFeature[ProductFeatureType.TURING_UNIVERSITY] && <>
          <div css={sidebarSectionStyle} style={{ marginTop: '10px' }}>
            <div css={sidebarTitleStyle}>
              <div>Turing University</div>
            </div>
          </div>
          <div
            css={reportItemStyle}
            role="button"
            tabIndex={0}
            className={
              isRouteActive(`/project/${currentProject?.id}/turing-university`)
                ? 'selected'
                : ''
            }
            // onClick={() => navigate(`/project/${currentProject?.id}/turing-university`)}
            onClick={() => window.open('https://university.turingsaas.com/', '_blank')}
          >
            Turing University Portal <ExportOutlined />
          </div>
        </>}



        {showIngredientComposition && (
          <IngredientCompositionSetupModal
            onClose={() => setShowIngredientComposition(false)}
          />
        )}
      </div>
    </span>
  );
};
