import { Collapse, CollapseProps, Typography } from "antd"
import React from "react"

interface ErrorTimeLineMessageProps {
  stackTrace: string
}

export const ErrorTimeLineMessage: React.FC<ErrorTimeLineMessageProps> = ({ stackTrace }) => {

  const { Paragraph } = Typography;

  const stackTraceComponent = (
    <Paragraph>
      {stackTrace}
    </Paragraph>
  );

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'An unexpected error ocurred. Details:',
      children: stackTrace,
    }
  ];

  return (
    <>
      <Collapse items={items}
        bordered={false}
        style={{ background: 'transparent' }}
      />
    </>
  )
}