/** @jsxImportSource @emotion/react */
import './campaignModal.less';
import { useEffect, useState } from 'react';
import { Form, Button, Modal, InputNumber, Tooltip, Select, Input } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useSoftDeleteCampaign } from '../../network/services/campaign.service';

type modalProps = {
  onClose: () => void;
  onConfirm: (data: any) => void;
  refeshProject?: boolean;
  data?: any
};
const { Option } = Select;

export const CampaignModal = ({ onClose, onConfirm, data }: modalProps) => {
  const isEditMode = Boolean(data);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const softDeleteMutation = useSoftDeleteCampaign();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        campaign_name: data.name,
        number_formulations: data.numberFormulations,
        number_rounds: data.iterationExecuted
      });
    }
  }, [data, form]);

  const cancelBtn = (
    <Button key="back" onClick={onClose}>
      Cancel
    </Button>
  );

  const handleOnConfirm = (data: any) => {
    setLoading(true);
    onConfirm(data)
  }

  const onFinish = (values: any) => {
    const { campaign_name, number_formulations, number_rounds } = values;

    if (isEditMode && data && number_rounds === 0) {
      softDeleteMutation.mutate(
        {
          campaignId: data.id
        },
        {
          onSuccess: res => {
            onClose()
          }
        }
      )
    }
    else {
      handleOnConfirm({ campaign_name, number_formulations, number_rounds })
    }
  };

  const saveCampaignBtn = (
    <Button
      loading={loading}
      className='primary-button'
      htmlType="submit"
      form="campaign-form"
      key="confirm"
    >
      {isEditMode ? "Save Changes" : "Execute Initiative"}
    </Button>

  );

  const footer = [
    cancelBtn,
    saveCampaignBtn
  ];
  return (
    <Modal
      title={isEditMode ? "Edit Initiative" : "New Initiative"}
      open={true}
      onCancel={onClose}
      footer={footer}
      centered
    >
      <div id='new-campaign-modal'>
        <Form
          form={form}
          name="basic"
          id="campaign-form"
          onFinish={onFinish}
        >
          {/* TITLE */}
          <span
            style={{
              display: 'block',
              color: '#7C858C',
              paddingBottom: 5,
              width: '100%',
              marginTop: 10
            }}
          >
            Initiative Name
          </span>
          <Form.Item
            name="campaign_name"

            rules={[
              { required: true, message: '' },
            ]}
          >
            <Input disabled={isEditMode} />
          </Form.Item>

          {/* FORMULATIONS */}
          <span
            style={{
              display: 'block',
              color: '#7C858C',
              paddingBottom: 5,
              width: '100%',
            }}
          >
            Number of formulations
          </span>
          <div className='formulations_container'>
            <Form.Item
              name="number_formulations"
              rules={[
                {
                  required: true, message: ''
                },
              ]}
              initialValue={1}
            >
              <Select
                className='input-number'
                placeholder="Formulations"
                allowClear

              >
                {Array.from({ length: 10 }, (_, index) => (
                  <Option key={index} value={index + 1}>
                    {index + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Tooltip
              placement="bottomLeft"
              mouseEnterDelay={0.3}
              title="The maximum number of recommended formulations Turing will return for testing."
            >
              <InfoCircleTwoTone className='info-circle' />
            </Tooltip>
          </div>

          {/* ITERATIONS */}
          <span
            style={{
              display: 'block',
              color: '#7C858C',
              paddingBottom: 5,
              width: '100%',
              marginTop: 10
            }}
          >
            Number of rounds
          </span>

          <Form.Item
            name="number_rounds"
            rules={[
              {
                required: true, message: ''
              },
            ]}
            initialValue={isEditMode ? data.iterationExecuted : 1}
          >
            <InputNumber
              className='input-number'
              placeholder="Rounds"
              value={isEditMode ? data.iterationExecuted : undefined}
              min={isEditMode ? data.iterationExecuted : 1}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal >
  );
};
