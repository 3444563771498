import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { IngredientGroupForm } from './ingredient-group-form/ingredient-group-form';
import {
  IngredientGroupData,
  IngredientGroupDataIn,
  IngredientGroupFormData,
} from './ingredients-group.interfaces';
import { IngredientGroupList } from './ingredients-group-list/ingredients-group-list';
import { useSession } from '../../../_shared/context';
import { IngredientList, VariableType } from '../../../../../__generated__/globalTypes';
import { useGetIngredients } from '../../../network/services/ingredient.service';
import './ingredients-group.less';
import {
  useCreateIngredientgroup,
  useGetIngredientsGroup,
} from '../../../network/services/ingredient-group.service';

const emptyElement: IngredientGroupFormData = {
  ingredientId: 0,
  ingredientName: '',
  name: '',
  percentage: 1,
  lowerBound: 0,
  upperBound: 0,
  groupSum: false
};

interface IngredientGroupProps {
  proj_id?: string;
}

export const IngredientGroup: React.FC<IngredientGroupProps> = ({
  proj_id,
}) => {
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  // const [ingredientGroups, setIngredientGroups] = useState<>([])
  const [ingredientsList, setIngredientsList] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);

  const {
    user,
    addIngredientGroup,
    setIngredientGroupList,
    ingredientsGroupList,
  } = useSession();
  const createGroupMutation = useCreateIngredientgroup();

  const {
    data: ingredients,
    isSuccess: ingredientsIsSuccess,
    refetch: refechIngredient
  } = useGetIngredients({
    projectId: proj_id,
    organizationId: user?.organizationId,
  });

  const {
    data: ingredientsGroup,
    isSuccess: ingredientGroupIsSuccess,
  } = useGetIngredientsGroup({
    projectId: proj_id,
    organizationId: user?.organizationId,
  });


  useEffect(() => {
    if (ingredients && typeof ingredients === 'object') {
      let aux: {
        id: number;
        name: string;
      }[] =
        ingredients.data.map((i: any) => {

          if (i.type !== VariableType.CATEGORICAL && i.type !== VariableType.ORDINAL)
            return {
              id: i.ingredient.id,
              name: i.ingredient.name,
            }
        }) ?? [];
      aux = aux.filter((e) => e !== undefined)
      setIngredientsList(aux);
    }
  }, [ingredients]);

  useEffect(() => {
    if (ingredientsGroup && typeof ingredientsGroup === 'object') {
      const aux = Object.keys(ingredientsGroup).map(k =>
        ingredientsGroup[k].map((e: any) => ({
          name: e.group.name,
          id: k,
          ingredient: {
            percentage: e.percentage,
            ingredientName: e.ingredient.name,
            ingredientId: e.ingredientId,
          },
          lowerBound: e.group.lowerBound,
          upperBound: e.group.upperBound,
          groupSum: e.group.groupSum
        }))
      );
      const groupData: IngredientGroupData[] = [];
      for (const groupArr of aux) {
        const toAdd: IngredientGroupData = {
          name: '',
          id: '',
          ingredient: [],
          lowerBound: 0,
          upperBound: 0,
          groupSum: false
        };
        for (const e of groupArr) {
          console.log(e);
          toAdd.name = e.name;
          toAdd.id = e.id;
          toAdd.groupSum = e.groupSum;
          toAdd.ingredient.push({
            ingredientId: e.ingredient.ingredientId,
            ingredientName: e.ingredient.ingredientName,
            percentage: e.ingredient.percentage,
          });
          toAdd.lowerBound = e.lowerBound;
          toAdd.upperBound = e.upperBound;
        }
        groupData.push(toAdd);
      }
      setIngredientGroupList(groupData);
    }
  }, [ingredientsGroup]);

  const onCreateGroup = () => {
    setIsAddMode(true);
  };

  const addGroup = (e: IngredientGroupData) => {
    setIsAddMode(false);
    // setIngredientGroupList(
    //   [
    //     ...ingredientsGroupList,
    //     e
    //   ]
    // )
    const payload: IngredientGroupDataIn = {
      group_name: e.name,
      creator_id: user?.id,
      ingredientsList: e.ingredient.map(i => ({
        ingredient_id: i.ingredientId,
        percentage: i.percentage,
      })),
      lower_bound: e.lowerBound,
      upper_bound: e.upperBound,
      groupSum: e.groupSum
    };
    createGroupMutation.mutate(
      {
        projectId: proj_id ?? '',
        organizationId: user?.organizationId ?? '',
        ingredientGroup: payload,
      },
      {
        onSuccess: async (response: any) => {
          console.log(response);
          const newgroup = response.data;
          addIngredientGroup({
            name: newgroup.name,
            id: newgroup.id,
            lowerBound: newgroup.lowerBound,
            upperBound: newgroup.upperBound,
            ingredient: e.ingredient,
            groupSum: e.groupSum
          });
        },
      }
    );
  };

  return (
    <div>
      <Button onClick={onCreateGroup} className="add-constraint-button">
        + Add new Group
      </Button>
      {isAddMode ? (
        <IngredientGroupForm
          ingredientsList={ingredientsList}
          addGroup={addGroup}
          element={emptyElement}
        />
      ) : (
        <div>
          {
            <>
              <IngredientGroupList />
            </>
          }
        </div>
      )}
    </div>
  );
};
