import { Button, notification, Spin, Switch } from 'antd';
import { useSession } from '../../_shared/context';
import {
  useGenerateGoalSimulation,
  useGetForecastGoalSimulation,
  useGetGoalsProgressDesirability,
} from '../../network/services/goal.service';
import React, { ReactNode, useEffect, useState, useTransition } from 'react';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import useWebSocket from '../../_shared/hooks/use-web-socket.hook';
import {
  useGetProjectGoalSimulatorStatus,
  useEnableGoalSimulator,
} from '../../network/services/project.service';
import { useScenarioDetail } from '../../_shared/context/scenario-detail-context';
import { PlayCircleOutlined } from '@ant-design/icons';
import './experiments-styles.less'
import { validateGoalSimulatorBounds } from '../../_shared/utils/util';
import { useTranslation } from 'react-i18next';

const pendingStatus = "PENDING";
export const GoalSimulation = ({ }: {}) => {
  const { t } = useTranslation()
  const { currentProject, user } = useSession();
  const { enableGoalSimulator, enabledGoalSimulator } = useScenarioDetail();
  const [loadingGoalSim, setLoadingGoalSim] = useState(false);
  const [validGoalSimulation, setValidGoalSimulation] = useState(true);
  const generateGoalSimulator = useGenerateGoalSimulation();
  const enableGoalSim = useEnableGoalSimulator();
  const [goalSimulationData, setGoalSimulatorData] = useState<any>()

  const {
    data: goalSimulation,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useGetForecastGoalSimulation({
    organizationId: currentProject?.organizationId,
    projectId: currentProject?.id,
    enableGoalSim: enabledGoalSimulator.enabled
  });



  const { goalSimulationFinished } = useWebSocket();

  useEffect(() => {
    if (goalSimulation?.data) {
      setGoalSimulatorData(goalSimulation?.data)
    }
  }, [goalSimulation])

  useEffect(() => {
    refetch();
  }, [goalSimulationFinished]);

  useEffect(() => {
    if (goalSimulation) {
      const valid = validateGoalSimulatorBounds(goalSimulation);
      setValidGoalSimulation(valid);
    }
  }, [goalSimulation])

  const handleGoalSimulator = (e: boolean) => {
    enableGoalSimulator(e);
  };

  const handleRunGoalSimulator = () => {
    setLoadingGoalSim(true)
    generateGoalSimulator.mutate({
      projectId: currentProject?.id,
      organizationId: currentProject?.organizationId,
      userId: user?.id
    }, {
      onSuccess: (data) => {
        if (data?.data?.status) {
          notification.success({ message: `Goal simulation is running, you will see the results soon!` });
        }
        setLoadingGoalSim(false)
      },
      onError: (data) => {
        if (data)
          notification.error({ message: `Can't trigger goal simulation` });
        setLoadingGoalSim(false)
      },
      onSettled: () => {
        refetch()
      }
    })
  }

  const renderTooltipWithoutRange = ({ payload, content, ...rest }: any) => {
    if (goalSimulation && payload) {
      const newPayload = payload.filter((x: any) => x.dataKey !== 'a');
      return <Tooltip payload={newPayload} {...rest} />;
    }
  };
  if (isLoading) return <Spin style={{ display: 'flex', justifyContent: 'center' }} />

  return (
    <>
      <span className="exploration-section-header-text">
        Goal Simulation (Beta)
        <div className="group-sum">
          <label>Enable goal simulation: </label>
          <Switch
            id="enable-goal-simulation"
            size={'small'}
            checked={enabledGoalSimulator.enabled}
            onChange={e => handleGoalSimulator(e)}
          />
        </div>
      </span>
      <p></p>
      <div className="container">
        {enabledGoalSimulator.enabled && (
          <>
            {!enabledGoalSimulator.missingFormulations ? (
              <>
                {goalSimulation && goalSimulation.status === pendingStatus ?
                  <>
                    <p
                      style={{
                        width: 300,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <b>A goal simulation was recently executed, and we are now awaiting the results</b>
                    </p>

                  </>
                  :
                  <>
                    {validGoalSimulation ?
                      <>
                        <div className="char-container">
                          <p className="title">Desirability</p>
                          {!goalSimulation || isLoading ? (
                            <p
                              style={{
                                width: 300,
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <b>No data available yet</b>
                            </p>
                          ) :
                            <ResponsiveContainer width={300} height={250}>
                              <ComposedChart
                                width={500}
                                height={400}
                                data={goalSimulation?.data?.desirability}
                                margin={{
                                  top: 10,
                                  right: 10,
                                  left: 0,
                                  bottom: 10,
                                }}
                              >
                                <Legend verticalAlign="top" height={36} />
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                  dataKey="name"
                                  label={{
                                    value: 'Round',
                                    position: 'insideBottom',
                                    offset: -5,
                                  }}
                                />
                                <YAxis
                                  label={{
                                    value: 'Max Desirability Score',
                                    angle: -90,
                                    position: 'insideBottomLeft',
                                    offset: 10,
                                    dy: -20,
                                  }}
                                />
                                <Tooltip content={renderTooltipWithoutRange} />
                                <Area
                                  type="monotone"
                                  dataKey="Confidence"
                                  stroke="none"
                                  fill="rgb(204 229 204)"
                                  connectNulls
                                  dot={false}
                                  activeDot={false}
                                />
                                <Line
                                  type="natural"
                                  dataKey="Forecast"
                                  stroke="rgb(14 127 8)"
                                  connectNulls
                                />
                              </ComposedChart>
                            </ResponsiveContainer>


                          }
                        </div>

                        <div className="char-container">
                          <p className="title">Probability of Success</p>
                          {!goalSimulation || isLoading ? (
                            <p
                              style={{
                                width: 300,
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <b>No data available yet</b>
                            </p>
                          ) :


                            <ResponsiveContainer width={300} height={250}>
                              <ComposedChart
                                width={500}
                                height={400}
                                data={goalSimulation?.data?.success_chance}
                                margin={{
                                  top: 10,
                                  right: 10,
                                  left: 0,
                                  bottom: 10,
                                }}
                              >
                                <Legend verticalAlign="top" height={36} />
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                  dataKey="name"
                                  label={{
                                    value: 'Round',
                                    position: 'insideBottom',
                                    offset: -5,
                                  }}
                                />
                                <YAxis
                                  label={{
                                    value: 'Percent Chance of success',
                                    angle: -90,
                                    position: 'insideBottomLeft',
                                    offset: 10,
                                    dy: 0,
                                  }}
                                />
                                <Tooltip content={renderTooltipWithoutRange} />
                                <Line
                                  type="natural"
                                  dataKey="Forecast"
                                  stroke="#f9a504"
                                  connectNulls
                                />
                              </ComposedChart>
                            </ResponsiveContainer>

                          }
                        </div>
                      </> :
                      <p>{t('discover-solutions.page.goalTab.messages.unableForecastDueData')}</p>
                    }

                    <Button
                      id="generate-goal-sim"
                      type="primary"
                      icon={<PlayCircleOutlined />}
                      onClick={handleRunGoalSimulator}
                      loading={loadingGoalSim}
                      disabled={!goalSimulationData || isLoading || goalSimulation.status === pendingStatus}

                    >
                      Run Goal Simulation
                    </Button>
                  </>
                }
              </>
            ) : (
              <div>{enabledGoalSimulator.message}</div>
            )}

          </>
        )}
      </div>
    </>
  );
};
