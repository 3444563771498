/** @jsxImportSource @emotion/react */

import posthog from 'posthog-js';
import { MouseEventHandler } from 'react';

export type DownloadFileButtonProps = {
  content: string;
  options: any;
  filename: string;
  onclick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  children: JSX.Element | string;
  dataForPostHog?: {
    projectId: string;
    initativeId: string;
  };
};

export const DownloadFile = (props: DownloadFileButtonProps) => {
  const getDownloadUrl = () => {
    const csv = new Blob([props.content], props.options);
    return URL.createObjectURL(csv);
  };

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={getDownloadUrl()}
      download={props.filename}
      onClick={() => {
        console.log(props.dataForPostHog);
        if (props.dataForPostHog) {
          posthog.capture('DOWNLOAD_INITIATIVE', {
            data: {
              ...props.dataForPostHog,
            },
          });
        }
        props.onclick;
      }}
    >
      {props.children}
    </a>
  );
};
