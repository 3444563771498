import { useIngredients } from '../../../../_shared/hooks';
import { FormulationItemType } from '../../../../../../__generated__/globalTypes';
import { FormulationType } from '../../../../_shared/context/formulations-context';
import { CustomRecordType } from '../../experiment-list-layout-v2';
import { Card, Table, Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

export const IngredientsCard = ({
  formulation,
}: {
  formulation: FormulationType;
}) => {
  const { ingredientByName } = useIngredients();

  const ingredients = formulation.items
    .filter(
      item =>
        item.type !== FormulationItemType.TARGET_MEASURED &&
        item.type !== FormulationItemType.TARGET_PREDICTED
    )
    .map((item, index) => {
      const ingredient = ingredientByName.get(item.variable.name);
      return {
        key: index,
        name: item.variable.name,
        percentage:
          !isNaN(Number(item.value)) && !ingredient?.unit
            ? `${item.value}%`
            : `${item.value} ${ingredient?.unit || ''}`,
      };
    });

  const ingredientColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Percentage (%)',
      dataIndex: 'percentage',
      key: 'percentage',
    },
  ];

  return (
    <>
      <Card title="Ingredients">
        <Table
          columns={ingredientColumns}
          dataSource={ingredients}
          pagination={false}
        />
      </Card>
    </>
  );
};
