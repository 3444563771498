import { useMutation } from "react-query";
import { ImportRepository } from "../repositories/import.repository";

export const useImportProject = () => {
  const mutation = useMutation((data: {
    org_id: string,
    data: any
  }) => {
    return ImportRepository.postImportFile(
      data.org_id,
      data.data
    );
  });
  return mutation;
};