import { useIngredients } from '../../../../_shared/hooks';
import {
  FormulationItemMetric,
  FormulationItemMetricType,
  FormulationItemType,
} from '../../../../../../__generated__/globalTypes';
import { FormulationType } from '../../../../_shared/context/formulations-context';
import { CustomRecordType } from '../../experiment-list-layout-v2';
import { Card, Table, Tag, Typography } from 'antd';
import React from 'react';
import { useSession } from '../../../../_shared/context';

const { Title } = Typography;
type Category = 'Analytical' | 'Sensory' | 'Consumer';

const categoryColors: Record<Category, string> = {
  Analytical: 'blue',
  Sensory: 'green',
  Consumer: 'purple',
};

export const OutcomesCard = ({
  formulation,
}: {
  formulation: FormulationType;
}) => {
  const { currentProject } = useSession();

  const outcomes = currentProject?.activeModel?.outcomes;

  const outcomesDataSource = formulation.items
    .filter(item => item.type === FormulationItemType.TARGET_PREDICTED)
    .map((item, index) => {
      const o = outcomes?.find(
        outcome => outcome.targetVariable === item.variable.name
      );
      let range: number[] = [];
      let category: string;
      if (o) range = [Number(o.lower), Number(o.upper)];
      category = o?.category?.name!;

      let confidenceIntervals: FormulationItemMetric | undefined;
      let confidenceIntervalsArray: string[] | undefined;
      const hasConfidenceIntervals = formulation.items.some((item: any) =>
        item.metrics.some(
          (metric: any) =>
            metric.type === FormulationItemMetricType.CONFIDENCE_INTERVAL
        )
      );
      if (item.type === FormulationItemType.TARGET_PREDICTED) {
        if (hasConfidenceIntervals) {
          confidenceIntervals = item.metrics.find(
            (metric: any) =>
              metric.type === FormulationItemMetricType.CONFIDENCE_INTERVAL
          );
          confidenceIntervalsArray = confidenceIntervals?.value.split(',');
        }
      }

      return {
        key: index,
        name: item.variable.name,
        value: item.value,
        range: `${range[0]} - ${range[1]}`,
        confidenceInterval: confidenceIntervalsArray
          ? `${confidenceIntervalsArray[0]} - ${confidenceIntervalsArray[1]}`
          : '-',
        category: category,
      };
    });

  const outcomeColumns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (category: Category) => (
        <Tag color={categoryColors[category] || 'default'}>{category}</Tag>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Predicted values',
      dataIndex: 'value',
      key: 'value',
      render: (_: string, record: any) => (
        <div>
          <div>{record.value}</div>
          <div style={{ fontSize: '0.8em', color: 'gray' }}>
            {record.confidenceInterval !== '-'
              ? `${record.confidenceInterval}`
              : 'No Confidence Interval'}
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Card title="Outcomes">
        <Table
          columns={outcomeColumns}
          dataSource={outcomesDataSource}
          pagination={false}
        />
      </Card>
    </>
  );
};
