import React, { useEffect, useRef } from "react"
import './turing-uni-wrapper.less'
export const TuringUniversityWrapper: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  // useEffect(() => {
  //   const iframe = iframeRef.current;
  //   if (iframe) {
  //     // const iframe = document.getElementById("myIframe");
  //     const iframeDocument = iframe?.contentDocument || iframe.contentWindow?.document;

  //     if (iframeDocument)
  //       iframeDocument.body.style.backgroundColor = "lightblue";

  //   }
  // }, []);
  return (
    <div>
      <iframe
        ref={iframeRef}
        src="http://turingsaasportal.usetiful.help/"
        style={{ width: '100%', height: '90vh', border: 'none' }}
        title="university-portal"
        className='turing-uni'
      />
    </div>
  )
}