/** @jsxImportSource @emotion/react */

import { Avatar, List } from 'antd';
import './messageItem.less';
import { IMessage, MessageType } from '../../../../_shared/types';

type IProps = {
  item: IMessage;
  id: number;
  itemToHighlight: string;
};

export const MessageItem = ({ item, id, itemToHighlight }: IProps) => {
  const css =
    item.userType === MessageType.SUPPORT ? 'list-item-inverted' : 'list-item';

  const [firstName, lastName] = item.userName.split(' ');

  const avatar = (
    <Avatar shape="circle">
      {firstName?.charAt(0)?.toLocaleUpperCase()}
      {lastName?.charAt(0)?.toLocaleUpperCase()}
    </Avatar>
  );

  const highlightClass = item.id === itemToHighlight ? 'new-message' : '';

  return (
    <>
      <List.Item
        key={id}
        className={`${css} ${highlightClass}`}
        style={{
          borderTop: item.firstUnreadItem
            ? '1px solid #ff00007d'
            : '1px solid #e0e0e0',
        }}
      >
        <div style={{ position: 'relative' }}>
          {item.firstUnreadItem && (
            <span
              style={{
                position: 'absolute',
                top: '-44px',
                left: '280px',
                backgroundColor: 'white',
                color: 'rgba(255, 0, 0, 0.83)',
                padding: '0 5px',
                borderRadius: '10px',
                fontSize: '9px',
                fontWeight: '500',
              }}
            >
              New
            </span>
          )}
        </div>
        <List.Item.Meta
          className={String(id)}
          avatar={avatar}
          title={item.userName}
          description={item.message}
          key={id}
        />
      </List.Item>
    </>
  );
};
