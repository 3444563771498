/** @jsxImportSource @emotion/react */

import { HistoryOutlined, SendOutlined } from '@ant-design/icons';
import { Flex, Input, Segmented, Spin, Tag, Typography } from 'antd';
import { useState } from 'react';
import '../../support/contactTuringSupport.less';
import { useSendLunaChatMessage } from '../../../network/services/luna-chat.service';
import { useSession } from '../../../_shared/context';

export const CopilotChat = () => {
  const { user, currentProject } = useSession();

  const [inputValue, setInputValue] = useState('');
  const sendMessage = useSendLunaChatMessage();

  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleSendMessage = () => {
    if (inputValue.trim() === '' || !user || !currentProject) {
      return;
    }
    setHasError(false);
    setLoading(true);
    setResponse('');
    sendMessage.mutate(
      {
        organizationId: user?.organizationId,
        projectId: currentProject.id,
        message: inputValue,
        userId: user.id,
      },
      {
        onSuccess: async (response?: any) => {
          if (!response) {
            setHasError(true);
          } else {
            setResponse(response.data.message);
          }
          setLoading(false);
        },
        onError: () => {
          setHasError(true);
          setLoading(false);
        },
      }
    );
  };

  return (
    <Flex
      style={{ width: 450, paddingLeft: 15, height: 'calc(100vh - 400px)' }}
      vertical
      gap={15}
    >
      <Typography.Title
        style={{ display: 'flex', alignItems: 'center', gap: 6, marginTop: 12 }}
        level={4}
      >
        Talk to Luna <Tag>Beta</Tag>
      </Typography.Title>
      {/**<Segmented
        options={[
          {
            label: 'Questions',
            value: 'questions',
          },
          {
            label: 'History',
            value: 'history',
            icon: <HistoryOutlined />,
          },
        ]}
        block
      />**/}
      <div className="chat-input-container" key={'chat-container'}>
        <Input
          placeholder="Ask a question"
          suffix={<SendOutlined onClick={handleSendMessage} />}
          value={inputValue}
          onChange={event => setInputValue(event.currentTarget.value)}
          onPressEnter={handleSendMessage}
          className="chat-input"
          disabled={loading}
        />
      </div>
      <Spin spinning={loading}>
        <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
          {hasError && (
            <>There was an issue completing your request. Please try again.</>
          )}
          {response}
        </Typography.Text>
      </Spin>
    </Flex>
  );
};
