import { useIngredients } from '../../../../_shared/hooks';
import {
  ConstraintType,
  CostOptimizationOption,
  FormulationItemType,
  JobStatus,
  ObjectiveType,
} from '../../../../../../__generated__/globalTypes';
import { FormulationType } from '../../../../_shared/context/formulations-context';
import { CustomRecordType } from '../../experiment-list-layout-v2';
import { Card, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSession } from '../../../../_shared/context';

const { Title } = Typography;

const objectiveTypeMapping: Record<ObjectiveType, string> = {
  [ObjectiveType.IN_RANGE]: 'Range',
  [ObjectiveType.MAXIMIZE]: 'Maximize',
  [ObjectiveType.MINIMIZE]: 'Minimize',
  [ObjectiveType.TARGET_VALUE]: 'Target',
};

interface OutcomeRange {
  upper: string | null | undefined;
  lower: string | null | undefined;
}

export const ObjectivesCard = ({
  design,
}: {
  design:
    | {
        __typename?: 'Design';
        id: string;
        maxNumberOfResults?: number | null;
        costOptimizationOption?: CostOptimizationOption | null;
        nteCost?: number | null;
        createdAt: any;
        enforceStrictly: boolean;
        iterationId: string;
        modelId: string;
        updatedAt: any;
        projectJob?: {
          __typename?: 'ProjectJobs';
          id: string;
          status: JobStatus;
        } | null;
        objectives: Array<{
          __typename?: 'Objective';
          id: string;
          iterationId?: string | null;
          targetVariable: string;
          objectiveType: ObjectiveType;
          importance: number;
          value?: string | null;
          lower?: string | null;
          upper?: string | null;
          createdAt: any;
          updatedAt: any;
          minTarget?: number | null;
          maxTarget?: number | null;
        }>;
        constraints: Array<{
          __typename?: 'Constraint';
          id: string;
          constraintType: ConstraintType;
          lowerBounds?: number | null;
          upperBounds?: number | null;
          coefficients?: Array<any> | null;
          values?: Array<any> | null;
          variables?: Array<string> | null;
          name?: string | null;
          ingredientCompositionId?: string | null;
        }>;
      }
    | null
    | undefined;
}) => {
  const { currentProject } = useSession();
  const [outcomesRange, setOutcomesRange] = useState<
    Record<string, OutcomeRange>
  >({});

  const objectives = design?.objectives;
  const outcomes = currentProject?.activeModel?.outcomes;

  /* Get the project setup outcome range to add to the table */
  useEffect(() => {
    let newOutcomesRange: Record<string, OutcomeRange> = {};
    if (objectives && outcomes)
      newOutcomesRange = objectives.reduce(
        (acc: Record<string, OutcomeRange>, o) => {
          const outcome = outcomes.find(
            outcome => outcome.targetVariable === o.targetVariable
          );
          if (outcome) {
            acc[o.targetVariable] = {
              upper: outcome.upper,
              lower: outcome.lower,
            };
          }
          return acc;
        },
        {}
      );
    setOutcomesRange(newOutcomesRange);
  }, [objectives, outcomes]);

  const columns = [
    {
      title: 'Variable',
      dataIndex: 'targetVariable',
      key: 'targetVariable',
    },
    {
      title: 'Type',
      dataIndex: 'objectiveType',
      key: 'objectiveType',
      render: (value: ObjectiveType) => objectiveTypeMapping[value],
    },
    {
      title: 'Configured values (project values)',
      key: 'range',
      render: (record: {
        targetVariable: string;
        objectiveType: string;
        value: any;
        lower: any;
        upper: any;
        targetValue: string;
      }) => {
        const outcomeRange = outcomesRange[record.targetVariable];
        if (!outcomeRange) {
          return '-';
        }

        if (record.objectiveType === 'TARGET_VALUE') {
          return `${record.value || '-'} (${outcomeRange.lower || '-'} - ${
            outcomeRange.upper || '-'
          })`;
        } else if (record.objectiveType === 'IN_RANGE') {
          return `${record.lower || '-'} - ${record.upper || '-'} (${
            outcomeRange.lower || '-'
          } - ${outcomeRange.upper || '-'})`;
        } else {
          return `(${outcomeRange.lower || '-'} - ${
            outcomeRange.upper || '-'
          })`;
        }
      },
    },
  ];

  return (
    <>
      {objectives && (
        <Card title="Objectives">
          <Table columns={columns} dataSource={objectives} pagination={false} />
        </Card>
      )}
    </>
  );
};
