import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Badge, Button, Input, List, Modal, Spin, message } from 'antd';
import { MessageOutlined, SendOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import './contactTuringSupport.less';
import useWebSocket from '../../_shared/hooks/use-web-socket.hook';
import VirtualList, { ListRef } from 'rc-virtual-list';
import { MessageItem } from './messageList/messageItem/messageItem';
import { IMessage, MessageType } from '../../_shared/types';
import {
  useGetHistoricalMessages,
  usePutMarkChatAsRead,
  useSendSupportMessage,
} from '../../network/services/chat-support.service';
import { useSession } from '../../_shared/context';
import { useDebouncedEffect } from '../../_shared/utils/util';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';

const ContainerHeight = 400;

const ContactTuringSupport = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, right: 0 });
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [inputValue, setInputValue] = useState('');
  const buttonRef = useRef(null);
  const {
    socket,
    isConnected,
    isConnecting,
    error,
    emit,
    messages: socketMessage,
  } = useWebSocket();
  const sendMessage = useSendSupportMessage();
  const { user, newMessage, setNewMessage } = useSession();
  const {
    data,
    isError,
    isLoading,
    isSuccess,
    refetch,
  } = useGetHistoricalMessages({
    organizationId: user?.organizationId!,
    userId: user?.id!,
  });
  const markChatAsReadMutation = usePutMarkChatAsRead();
  const [messageCount, setMessageCount] = useState(0);
  const [itemToHighlight, setItemToHighlight] = useState<string>('');

  const listRef = useRef<ListRef>(null);
  const hasNewMessage = Boolean(newMessage);

  useEffect(() => {
    if (hasNewMessage) {
      setIsModalVisible(true);
    }
  }, [newMessage]);

  const getMessage = (id: string) => {
    const index = messages.findIndex((x: any) => x.id === id);
    return index;
  };

  useDebouncedEffect(
    () => {
      if (hasNewMessage) {
        setItemToHighlight(newMessage);
        scrollToBottom(getMessage(newMessage));
      } else if (isModalVisible && messages && listRef) {
        scrollToBottom(messages.length - 1);
      }
    },
    [isModalVisible, messages, listRef, hasNewMessage],
    300
  );

  const scrollToBottom = (index: number) => {
    if (listRef.current) {
      const element = document.getElementsByClassName(
        `ant-list-item-meta ${index}`
      );
      console.log('the element', element);
      listRef.current.scrollTo({
        top: element[0]?.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (user) refetch();
  }, [user]);

  useEffect(() => {
    if (data?.data) mapMessages(data.data.messages);
  }, [data]);

  useEffect(() => {
    if (messages.length) {
      const messagesUnreadCount = messages.filter(
        (message: any) => !message.isRead
      ).length;
      setMessageCount(messagesUnreadCount);
    } else {
      setMessageCount(0);
    }
  }, [messages]);

  useEffect(() => {
    if (socketMessage) {
      const updatedSocketMessage: IMessage = {
        id: socketMessage.id,
        userId: socketMessage.userId,
        userType: MessageType.SUPPORT,
        userName: socketMessage.userName,
        message: socketMessage.message,
        avatar: socketMessage.avatar,
        isRead: socketMessage.isRead,
      };
      const messagesList = [...messages, updatedSocketMessage];
      mapMessages(messagesList);
    }
  }, [socketMessage]);

  const mapMessages = (data: any) => {
    const unreadIndex = data.findIndex((item: any) => !item.isRead);
    setMessages(
      data.map((item: any, index: number) => {
        return {
          id: item.id,
          userId: item.userId,
          avatar: item.avatar,
          userName: item.userName,
          userType: item.userType,
          message: item.message,
          isRead: item.userType === MessageType.CUSTOMER ? true : item.isRead,
          firstUnreadItem: index === unreadIndex ? true : false,
        };
      })
    );
  };

  const markAllAsRead = () => {
    const updatedMessages = messages.map(message => ({
      ...message,
      isRead: true,
    }));
    setMessages(updatedMessages);
    setNewMessage('');
  };

  const handleMarkAsReadMessage = async () => {
    markChatAsReadMutation.mutate({
      organizationId: user?.organizationId!,
      user_id: user?.id!,
    });
    markAllAsRead();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputValue.trim() === '') {
      return;
    }
    sendMessage.mutate(
      {
        organizationId: user?.organizationId,
        constraint: {
          user_id: user?.id,
          message: inputValue,
        },
      },
      {
        onSuccess: async (response: any) => {
          const message: IMessage = {
            id: uuid(),
            userId: user?.id,
            userType: MessageType.CUSTOMER,
            userName: `${user?.firstName} ${user?.lastName}`,
            message: inputValue,
          };
          setMessages(prevMessages => {
            const updatedMessages = prevMessages.map(msg => ({
              ...msg,
              isRead: true,
              firstUnreadItem: false,
            }));
            return [...updatedMessages, { ...message, isRead: true }];
          });
        },
      }
    );
    setInputValue('');
  };

  const input = (
    <div className="chat-input-container" key={'chat-container'}>
      <Input
        placeholder="Write a reply..."
        suffix={<SendOutlined onClick={handleSendMessage} />}
        value={inputValue}
        onChange={handleInputChange}
        onPressEnter={handleSendMessage}
        onFocus={handleMarkAsReadMessage}
        className="chat-input"
      />
    </div>
  );
  const footer = [input];

  return (
    <>
      <Badge
        className="badge-circle-chat"
        count={messageCount}
        overflowCount={99}
        size="small"
      >
        <Button
          type="primary"
          shape="circle"
          icon={<MessageOutlined />}
          ref={buttonRef}
          size="large"
          onClick={showModal}
          className="chat-button"
        />
      </Badge>
      {
        <Modal
          title="Contact Turing Support"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={footer}
          className="bottom-right-modal"
          transitionName=""
          maskTransitionName=""
        >
          {isLoading ? (
            <Spin tip="Loading..." />
          ) : (
            <>
              <List id="list-messages">
                <VirtualList
                  id="chat-list"
                  data={messages}
                  height={ContainerHeight}
                  itemHeight={6}
                  itemKey="id"
                  ref={listRef}
                >
                  {(item: IMessage, index) => (
                    <MessageItem
                      item={item}
                      key={index}
                      id={index}
                      itemToHighlight={itemToHighlight}
                    />
                  )}
                </VirtualList>
              </List>
            </>
          )}
        </Modal>
      }
    </>
  );
};

export default ContactTuringSupport;
