import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type NoFeedBackDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  openModal: boolean;
};

export const NoFeedBackDialog: React.FC<NoFeedBackDialogProps> = ({
  onClose,
  onConfirm,
  openModal,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(openModal);
  const { t } = useTranslation();
  const DialogAlertText = t(
    'discover-solutions.page.noFeedbackDialog.messages.dialogText'
  );

  const handleGiveMoreFormulation = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <Modal
        title={t('discover-solutions.page.noFeedbackDialog.title')}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={handleGiveMoreFormulation}
          >
            GENERATE RECOMMENDATIONS
          </Button>,
        ]}
      >
        <p>{DialogAlertText}</p>
      </Modal>
    </>
  );
};
