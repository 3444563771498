import { useQuery } from "react-query"
import { ConstraintRepository } from "../repositories/constraint.repository";
import { ExportRepository } from "../repositories/export.repository";

export const useGetExportFile = (data: {
  organizationId: string;
  projectId: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getExportFile`, data.organizationId, data.projectId],
    ExportRepository.getExportFile,
    {
      enabled: false,
      cacheTime: 0,
      select: response => {
        return response;
      },
    }
  );
};