import { useMutation, useQuery } from 'react-query';
import { ChatSupportRepository } from '../repositories/chat-support.repository';

export const useSendSupportMessage = () => {
  const mutation = useMutation((data: any) => {
    return ChatSupportRepository.postSendMessage(data);
  });
  return mutation;
};

export const useGetHistoricalMessages = (data: {
  organizationId: string;
  userId: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getHistoricalMessages `, data.organizationId, data.userId],
    ChatSupportRepository.getHistoricalMessages,
    {
      enabled: true,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const usePutMarkChatAsRead = () => {
  const mutation = useMutation((data: any) => {
    return ChatSupportRepository.putMarkChatAsRead(data);
  });
  return mutation;
};
